/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import openai from '~/services/openai';
import AssistantModel from '../assistant';
import { IAssistantsModel } from './model';

export async function createVectorStore(file: File) {
  const response = await openai.beta.vectorStores.create({
    name: 'Vector Store'
  });
  await openai.beta.vectorStores.files.uploadAndPoll(response.id, file);

  return response;
}

export async function createFile(file: File) {
  const response = await openai.files.create({
    file,
    purpose: 'assistants'
  });

  return response;
}

export async function downloadPdf(link: string) {
  const pdf = await fetch(link);
  const blob = await pdf.blob();
  const file = new File([blob], 'report.pdf', { type: 'application/pdf' });
  return file;
}

export function createJson(data: string) {
  const blob = new Blob([data], { type: 'application/json' });
  const file = new File([blob], 'data.json', { type: 'application/json' });
  return file;
}

export default (self: IAssistantsModel) => ({
  create: flow(function* create(file, options: { name: string; metadata: any; instructions: string }) {
    const response = yield openai.beta.assistants.create({
      model: 'gpt-4o',
      tools: [{ type: 'code_interpreter' }],
      tool_resources: {
        code_interpreter: {
          file_ids: [file.id]
        }
      },
      ...options
    });
    self.add(AssistantModel.create(response));
    return response;
  })
});
