import React, { useCallback, useEffect, useMemo } from 'react';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import { OnChange } from 'react-final-form-listeners';
import groupBy from 'lodash/fp/groupBy';
import compact from 'lodash/fp/compact';
import sortBy from 'lodash/fp/sortBy';
import { action, computed, observable } from 'mobx';
import { useForm, useFormState } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import DataPoints from '~/mst/models/data_points/node';
import DevicesSelect from '~/components/devices_select';
import I18n from '~/utils/i18n';
import { getOptionsForPayload } from '~/components/final_form/select/utils';

import Select from '~/components/final_form/select';
import TextInput from '~/components/final_form/text_input';
import FormGrid from '~/components/@material-extend/form_grid';
import WidgetConfigurationForm from '~/components/widgets/common/configuration_form';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import type { IDashboard } from '~/mst/models/dashboard';
import { filterByUserSettings } from '~/mst/models/data_points/node/views';

type FormProps = {
  onClose: () => void;
  onSave: () => void;
  isNew: boolean;
  dashboard: IDashboard;
};

const params = observable(
  {
    node_id: null,
    setNodeId(value) {
      this.node_id = value;
    }
  },
  {
    setNodeId: action
  }
);

export function NodeWidgetConfigurationForm({ onClose, onSave, isNew, dashboard }: FormProps) {
  const { change } = useForm();
  const { nodes, auth } = useMst();
  useFetch(nodes, { organization_id: dashboard?.organization_id });

  const {
    invalid,
    values: { node_id: nodeIds }
  } = useFormState({ subscription: { invalid: true, values: true } });

  useEffect(() => params.setNodeId(getOptionsForPayload(nodeIds).filter((id) => Boolean(nodes.getById(id)))), [nodes, nodeIds]);

  const dataPoints = useMemo(() => DataPoints.create({}), []);
  useFetch(dataPoints, params);

  const tempUnits = auth?.user?.temp_units;
  const dataPointsValueLabelPairs = useMemo(
    () => computed(() => filterByUserSettings(dataPoints.defaultValueLabelPairs, tempUnits)),
    [dataPoints.defaultValueLabelPairs, tempUnits]
  ).get();

  const parameterOptions = useMemo(
    () =>
      computed(() => {
        return flow(
          compact,
          groupBy(({ label }) => label),
          map.convert({ cap: false })((values, name) => ({ models: values.map(({ model }) => model), label: name, value: name })),
          sortBy('label')
        )(dataPointsValueLabelPairs);
      }),
    [dataPointsValueLabelPairs]
  ).get();

  const handleSave = useCallback(() => {
    onSave();
    onClose();
  }, [onSave, onClose]);

  return (
    <WidgetConfigurationForm isNew={isNew} onClose={onClose} onSave={handleSave} disabled={invalid}>
      <FormGrid>
        <TextInput name="name" label="Name" />
        <DevicesSelect
          multiple
          allowSelectAll
          name="node_id"
          label={I18n.t('models.node')}
          options={nodes.monitorableValueLabelPairs}
          loading={nodes.isFetching}
        />
        <Select
          searchable
          name="selected_path"
          label={I18n.t('models.parameter')}
          options={parameterOptions}
          onChange={(value) => {
            change('selected_path', {
              value,
              label: value,
              models: parameterOptions.find((option) => option.value === value)?.models
            });
          }}
          loading={dataPoints.isFetching}
        />
      </FormGrid>
      <OnChange name="node_id">
        {() => {
          dataPoints.reset();
          change('selected_path', []);
        }}
      </OnChange>
    </WidgetConfigurationForm>
  );
}

export default observer(NodeWidgetConfigurationForm);
