/* eslint-disable @typescript-eslint/naming-convention */
import { getIdentifier } from '~/mst/utils';
import { IUsersModel } from './model';

export default (self: IUsersModel) => {
  return {
    get filteredList() {
      return self.filteredModels.map((user) => ({
        id: getIdentifier(user),
        fullName: user.fullName,
        email: user.email,
        picture: user.picture,
        createdAt: user.created_at,
        lastLogin: user.last_login_at,
        roles: user.organizationsRoles
      }));
    }
  };
};
