import React, { useCallback, useMemo } from 'react';
import { getIdentifier } from '~/mst/utils';
import { observer } from 'mobx-react-lite';
import toasts from '~/utils/toasts';
import { computed } from 'mobx';
import get from 'lodash/get';
import { Form as FinalForm } from 'react-final-form';
import { INode } from '~/mst/models/node';
import useFetch from '~/hooks/use_fetch';
import useMst from '~/hooks/use_mst';
import { PERMISSIONS } from '~/mst/models/permissions/types';
import useLocales from '~/hooks/use_locales';

import { captureException } from '~/services/sentry';
import PublicDashboardForm from './public_dashboard_form';

function PublicDashboardContainer({ node }: { node: INode }) {
  const { tokens } = useMst();
  const { t } = useLocales();

  useFetch(tokens);

  const token = useMemo(
    () => computed(() => tokens.toArray.find(({ query }) => node && query?.node_id === getIdentifier(node))),
    [tokens, node]
  ).get();

  useFetch(token);

  const initialValues = useMemo(() => computed(() => ({ ...get(node, 'user_metadata', {}), short_url: Boolean(token) })), [node, token]).get();

  const handleOnSubmit = useCallback(
    async (values) => {
      try {
        if (initialValues.short_url !== values.short_url) {
          if (values.short_url) {
            await tokens.create({ node_id: getIdentifier(node), organization: node?.organization_id, capabilities: [PERMISSIONS.ReadDevices] });
            toasts.success(t('notifications.success.node_public_link_created'));
          } else {
            await token.destroy();
            toasts.success(t('notifications.success.node_public_link_deleted'));
          }
        }
        await node.update({ user_metadata: values });
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [node, token, tokens, initialValues, t]
  );

  return (
    <FinalForm onSubmit={handleOnSubmit} initialValues={initialValues} subscription={{ submitting: true, pristine: true, dirty: true }}>
      {({ handleSubmit }) => {
        return <PublicDashboardForm handleSubmit={handleSubmit} model={node} token={token} />;
      }}
    </FinalForm>
  );
}

export default observer(PublicDashboardContainer);
