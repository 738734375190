/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import Yup from '~/utils/yup';
import { getSnapshot } from 'mobx-state-tree';
import { useParams } from 'react-router-dom';
import pick from 'lodash/pick';
import { computed } from 'mobx';
import { Form as FinalForm } from 'react-final-form';

import Modal from '~/components/modal';
import useLocales from '~/hooks/use_locales';
import { useValidationSchema } from '~/hooks/use_validate';
import useMst from '~/hooks/use_mst';
import { getOptionsForPayload } from '~/components/final_form/select/utils';

import ChartWidget, { IChartWidgetStore } from '~/mst/models/widget/chart_widget';
import { captureException } from '~/services/sentry';
import toasts from '~/utils/toasts';
import ChartWidgetForm from './form';

type AddEditChartConfigurationProps = {
  open: boolean;
  onClose: () => void;
  widget?: IChartWidgetStore;
};

function AddEditChartConfiguration({ open, onClose, widget }: AddEditChartConfigurationProps) {
  const { dashboards, nodes } = useMst();
  const { id } = useParams();
  const dashboard = dashboards.getById(id);
  const isNew = !widget;
  const { t } = useLocales();

  const initialValues = useMemo(
    () =>
      computed(() =>
        pick(isNew ? { name: t('chart_widget.config_dialog_title') } : getSnapshot(widget), 'node_id', 'selected_data_point_ids', 'name')
      ),
    [widget, isNew, t]
  ).get();

  const AddEditNodeSchema = Yup.object().shape({
    node_id: Yup.array().required('Required').min(1, 'Required'),
    selected_data_point_ids: Yup.array().required('Required').min(1, 'Required')
  });

  const validate = useValidationSchema(AddEditNodeSchema);

  const handleSave = useCallback(
    (values) => {
      try {
        const mappedDataPointsIds = getOptionsForPayload(values.selected_data_point_ids);
        const mappedNodesIds = getOptionsForPayload(values.node_id);
        const payload = {
          name: values.name,
          node_id: mappedNodesIds.filter((nodeId) => Boolean(nodes.getById(nodeId))),
          selected_data_point_ids: mappedDataPointsIds
        };
        if (widget) {
          widget.update(payload);
        } else {
          const newWidget = ChartWidget.create(payload);
          const breakpoints = dashboard.widgets.lastPositions(8, 4);
          newWidget.setLayout(breakpoints);
          dashboard.widgets.create(newWidget);
        }
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [widget, dashboard, t, nodes]
  );

  return (
    <Modal open={open} handleClose={onClose} title={isNew ? t('models.chart_widget_create') : t('models.chart_widget_update')}>
      <FinalForm onSubmit={handleSave} initialValues={initialValues} validate={validate}>
        {({ handleSubmit }) => <ChartWidgetForm isNew={isNew} onClose={onClose} onSave={handleSubmit} dashboard={dashboard} />}
      </FinalForm>
    </Modal>
  );
}

export default AddEditChartConfiguration;
