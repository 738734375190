import { styled } from '@mui/material/styles';
import { pxToRem } from '~/theme/typography';

const LockedStyled = styled('span')(({ theme }) => ({
  padding: '2px 8px',
  color: theme.palette.primary.muted,
  fontSize: pxToRem(12),
  fontStyle: 'normal',
  fontWeight: 600,
  background: 'rgba(159, 145, 171, 0.16)',
  borderRadius: 6
}));

export default LockedStyled;
