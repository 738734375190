import React, { useCallback, useState } from 'react';
import { Settings, Delete, ContentCopy, Share, Edit } from '@mui/icons-material';
import { Menu, MenuItem, Button, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import toasts from '~/utils/toasts';
import ConfirmDialog from '~/components/confirm_dialog';
import useNavigate from '~/hooks/use_navigate';
import useMst from '~/hooks/use_mst';
import { PERMISSIONS } from '~/mst/models/permissions/types';
import useLocales from '~/hooks/use_locales';
import ShareModal from './share_modal';
import EditModal from './edit_modal';

function Actions() {
  const { navigate } = useNavigate();
  const { t } = useLocales();
  const { auth } = useMst();
  const { dashboards } = useMst();
  const { id } = useParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  const open = Boolean(anchorEl);
  const dashboard = dashboards.getById(id);
  const canManage = auth?.user?.hasPermission(PERMISSIONS.ManageReports, dashboard?.organization_id);
  const canRead = auth?.user?.hasPermission(PERMISSIONS.ReadReports, dashboard?.organization_id);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleClone = useCallback(async () => {
    try {
      const cloned = await dashboard.clone();
      navigate(`dashboards/${cloned.id}`);
    } catch (e) {}
  }, [dashboard, navigate]);

  const handleStartDelete = useCallback(() => {
    handleClose();
    setIsDeleting(true);
  }, [handleClose]);

  const handleCancelDelete = useCallback((event) => {
    event.stopPropagation();
    setIsDeleting(false);
  }, []);

  const handleDeleteDashboard = useCallback(
    async (event) => {
      try {
        event.stopPropagation();
        await dashboards.destroy(dashboard);
        toasts.success(t('notifications.success.report_deleted'));
        handleCancelDelete(event);
        navigate(`dashboards`);
      } catch (e) {}
    },
    [dashboards, dashboard, handleCancelDelete, t, navigate]
  );

  if (!canManage && !canRead) {
    return null;
  }

  return (
    <>
      <Button variant="outlined" onClick={handleClick} sx={{ minWidth: 'auto', px: '4px' }} size="small">
        <Settings />
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiSvgIcon-root': {
              marginRight: 2
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {canRead && (
          <MenuItem onClick={() => setIsEditOpen(true)}>
            <Edit fontSize="small" />
            {t('base.buttons.edit')}
          </MenuItem>
        )}
        {canRead && (
          <MenuItem onClick={handleClone}>
            <ContentCopy fontSize="small" />
            {t('base.buttons.clone')}
          </MenuItem>
        )}
        {dashboard.canBeShared && canManage && (
          <MenuItem onClick={() => setIsShareOpen(true)}>
            <Share fontSize="small" />
            {t('base.buttons.share')}
          </MenuItem>
        )}
        <Divider />
        {canRead && (
          <MenuItem onClick={handleStartDelete} sx={{ color: (theme) => theme.palette.error.main }}>
            <Delete fontSize="small" />
            {t('base.buttons.delete')}
          </MenuItem>
        )}
      </Menu>
      <ConfirmDialog
        open={isDeleting}
        onClose={handleCancelDelete}
        onConfirm={handleDeleteDashboard}
        title="Are you sure?"
        content={`Are you sure you want to delete this dashboard? You can't undo this action`}
        confirmLabel="Delete"
        confirmButtonType="error"
      />
      <ShareModal open={isShareOpen} handleModalClose={() => setIsShareOpen(false)} dashboard={dashboard} />
      <EditModal open={isEditOpen} handleModalClose={() => setIsEditOpen(false)} dashboard={dashboard} />
    </>
  );
}

export default observer(Actions);
