import { DataTypeProvider } from '@devexpress/dx-react-grid';
import useLocales from '~/hooks/use_locales';
import LockedStyled from './styled';

export default function SharedLeasedFormatter(props: DataTypeProvider.ValueFormatterProps) {
  const {
    row: { is_locked: isLocked }
  } = props;
  const { t } = useLocales();

  if (!isLocked) return null;

  return <LockedStyled>{t('base.labels.locked').toLowerCase()}</LockedStyled>;
}
