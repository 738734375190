/* eslint-disable @typescript-eslint/naming-convention */
import { getParent } from 'mobx-state-tree';
import { getIdentifier } from '~/mst/utils';
import type { IWidget } from '~/mst/models/widget';
import type { IBreakpointLayout } from './model';

export default (self: IBreakpointLayout) => ({
  get gridLayout() {
    const widget = getParent<IWidget>(self, 2);
    return {
      i: getIdentifier(widget),
      x: self.x,
      y: self.y,
      w: self.w,
      h: self.h,
      isBounded: true,
      minH: 3,
      minW: 4
    };
  }
});
