/* eslint-disable @typescript-eslint/naming-convention */
import pick from 'lodash/pick';

import { PERMISSIONS } from '~/mst/models/permissions/types';
import I18n from '~/utils/i18n';
import { IOrganizationModel } from '../model';
import { OrganizationAddressTypes } from '../types';

export default (self: IOrganizationModel) => {
  function getAddress(type: OrganizationAddressTypes) {
    return self.addresses.find((address) => address.type === type);
  }
  return {
    get searchString() {
      const searchFields = ['name', 'shippingLocation', 'external_id'];
      return Object.values(pick(self, searchFields)).join(' ').toLowerCase();
    },
    get shippingLocation() {
      const address = getAddress(OrganizationAddressTypes.shipping);
      return address?.location;
    },
    get permissionValueLabelPairs() {
      return (self.permissions || []).map((item) => ({
        value: item,
        label: I18n.t(`permissions.${item}`)
      }));
    },
    get hasManagerAccess() {
      return self.permissions.includes(PERMISSIONS.ManageUsers);
    },
    get canManageReports() {
      return self.permissions.includes(PERMISSIONS.ManageReports);
    },
    get canReadReports() {
      return self.permissions.includes(PERMISSIONS.ReadReports);
    },
    get hasOwnerAccess() {
      return self.permissions.includes(PERMISSIONS.ManageOrganizations);
    },
    get isAethair() {
      return self.name?.toLowerCase().includes('aethair');
    }
  };
};
