import React, { useCallback, useMemo } from 'react';
import { getIdentifier } from '~/mst/utils';
import Yup from '~/utils/yup';
import { computed } from 'mobx';
import { Form as FinalForm } from 'react-final-form';

import Modal from '~/components/modal';
import { useValidationSchema } from '~/hooks/use_validate';
import useLocales from '~/hooks/use_locales';
import type { IAdamSensor } from '~/mst/models/device/sensor/adam';
import toasts from '~/utils/toasts';
import type { IDataPointNodeBase } from '~/mst/models/data_point/node/base';
import { captureException } from '~/services/sentry';
import ConfigureMeasurementForm from './form';

type ConfigureMeasurementModalProps = {
  open: boolean;
  dataPoint: IDataPointNodeBase;
  onClose: () => void;
  sensor: IAdamSensor;
};

function ConfigureMeasurementModal({ open, onClose, sensor, dataPoint }: ConfigureMeasurementModalProps) {
  const { t } = useLocales();
  const metricDataPoint = sensor?.metricDataPoints.find(({ linked }) => linked.includes(dataPoint?._id));

  const initialValues = useMemo(
    () => computed(() => ({ name: metricDataPoint ? `${metricDataPoint?.name}, ${metricDataPoint?.unit}` : null })),
    [metricDataPoint]
  ).get();

  const FormSchema = Yup.object().shape({
    name: Yup.string().required().nullable()
  });

  const validate = useValidationSchema(FormSchema);

  const handleSave = useCallback(
    async (values) => {
      try {
        const { name } = values;
        const payload = {
          conditions: {
            link: [getIdentifier(dataPoint)]
          },
          name
        };
        await sensor?.node?.data_points.createMetric(payload);
        onClose();
        toasts.success(t('notifications.success.add_stat_message'));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [sensor, onClose, t, dataPoint]
  );

  return (
    <Modal open={open} handleClose={onClose} title={t('sensor.data_point.add_link_title', { channel_name: dataPoint?.presentName })}>
      <FinalForm onSubmit={handleSave} initialValues={initialValues} validate={validate}>
        {({ handleSubmit }) => <ConfigureMeasurementForm handleSubmit={handleSubmit} handleClose={onClose} sensor={sensor} dataPoint={dataPoint} />}
      </FinalForm>
    </Modal>
  );
}

export default ConfigureMeasurementModal;
