import { types as t, Instance } from 'mobx-state-tree';
import DateType from '~/mst/models/abstract/date_time';
import { toIsoFromTs } from '~/utils/format_date';

export const CreatedAt = t
  .model({
    created_at: t.maybeNull(DateType),
    updated_at: t.maybeNull(DateType)
  })
  .views((self) => ({
    get createdAtIso() {
      return toIsoFromTs(self.created_at);
    },
    get updatedAtIso() {
      return toIsoFromTs(self.updated_at);
    }
  }));

export interface ICreatedAt extends Instance<typeof CreatedAt> {}
export default CreatedAt;
