import { useEffect, useCallback } from 'react';
import { Container } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';

import Box from '@mui/material/Box';
import { Wrapper as MapWrapper } from '@googlemaps/react-wrapper';

import { PATHS } from '~/utils/constants';
import useMst from '~/hooks/use_mst';
import Filters from '~/components/filters';
import useFetch from '~/hooks/use_fetch';
import { MapObservable, render } from '~/components/widgets/map_widget/content';

const deviceFilterGroups = ['device-status', 'device-type', 'sensors'];
const { VITE_GOOGLE_MAPS_KEY } = process.env;

function MapPage() {
  const { nodes } = useMst();
  const { auth } = useMst();

  const { isFetched, isFetching } = useFetch(nodes, { includes: ['profiles'] });

  const handleRefresh = useCallback(async () => {
    nodes.fetch({ includes: ['profiles'], organization_id: auth.organizationId }, { noCache: true });
  }, [auth.organizationId, nodes]);

  useEffect(
    () =>
      autorun(async () => {
        if (nodes.isFetched && nodes.isFetching === false) {
          await nodes.fetchDataPoints({ last: 1, path: [PATHS.AQ, PATHS.ONLINE, PATHS.LOCATION], organization_id: auth.organizationId });
          nodes.buildFilters(deviceFilterGroups);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        padding: '0 !important',
        position: 'relative'
      }}
    >
      <>
        <Box
          sx={{
            top: 15,
            left: 15,
            width: '35%',
            minWidth: '300px',
            zIndex: '10',
            display: 'flex',
            position: 'absolute'
          }}
        >
          <Filters collection={nodes} groups={deviceFilterGroups} handleRefresh={handleRefresh} />
        </Box>
        <MapWrapper apiKey={VITE_GOOGLE_MAPS_KEY} render={render} libraries={['places', 'marker']}>
          <MapObservable nodes={nodes} isPinnable mapId="e2b1b4ca24b54ab8" isLoading={!isFetched || isFetching} />
        </MapWrapper>
      </>
    </Container>
  );
}

export default observer(MapPage);
