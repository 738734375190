/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';
import Syncable from '~/mst/models/abstract/syncable';
import CollectionArray from '~/mst/models/abstract/collection_array';
import Message from '../message';

const MessagesModel = t.compose(CollectionArray(Message), Syncable, Fetchable({ cache: true }));

export interface IMessagesModel extends Instance<typeof MessagesModel> {}

export default MessagesModel;
