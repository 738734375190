import React, { useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';

import getAuth0Client from '~/services/auth0';
import { read as readUser } from '~/api/rest/users';
import { token } from '~/api/axios';
import useLocales from '~/hooks/use_locales';
import LoadingScreen from '~/components/loading_screen';
import useMst from '~/hooks/use_mst';

const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

export const hasAuthParams = (searchParams = window.location.search) =>
  (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) && STATE_RE.test(searchParams);

type Auth0ProviderProps = {
  children: ReactNode;
};

const logoutDefaults = {
  logoutParams: {
    returnTo: `${window.location.origin}/login.html`
  }
};

const AuthProvider = observer(({ children }: Auth0ProviderProps) => {
  const navigate = useNavigate();
  const [client] = useState(getAuth0Client());
  const { changeLanguage } = useLocales();
  const { auth } = useMst();
  useEffect(() => {
    (async () => {
      try {
        auth.startFetching();
        if (hasAuthParams()) {
          await client.handleRedirectCallback();
          navigate('/');
        }
        await client.getTokenSilently();
        const { __raw: idToken } = await client.getIdTokenClaims();
        const user = await client.getUser();
        token.setToken(idToken);
        const { data } = await readUser(user?.sub!);
        auth.setUser(data);
        if (auth.locale) {
          changeLanguage(auth.locale);
        }
        auth.finishFetching();
      } catch (e) {
        if (e.error === 'login_required') {
          client.logout(logoutDefaults);
        } else if (e.error === 'missing_refresh_token') {
          client.loginWithRedirect();
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  if (token.isAuthorized() === false || auth.isFetching) {
    return (
      <Stack direction="column" height="100vh" alignItems="center" justifyContent="center">
        <LoadingScreen isStarting />
      </Stack>
    );
  }

  return children;
});

export default AuthProvider;
