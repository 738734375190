import { types as t, Instance } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';
import { Model } from '~/mst/models/abstract/model';
import Assistants from './assistants';
import Threads from './threads';

const GptModel = t.compose(
  t.model({
    assistants: t.optional(Assistants, {}),
    threads: t.optional(Threads, {})
  }),
  Model,
  Fetchable({ cache: true })
);

export interface IGptModel extends Instance<typeof GptModel> {}

export default GptModel;
