import { Instance, SnapshotOut } from 'mobx-state-tree';
import CollectionArrayProcessor from '~/mst/models/abstract/collection_array_processor';
import model from './model';
import actions from './actions';

const MessagesStore = model.actions(actions);

export interface IMessagesStore extends Instance<typeof MessagesStore> {}
export interface IMessagesStoreSnapshot extends SnapshotOut<typeof MessagesStore> {}

export default CollectionArrayProcessor(MessagesStore);
