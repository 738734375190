import AddIcon from '@mui/icons-material/Add';
import { Grid, Stack } from '@mui/material';

import useModal from '~/hooks/use_modal';
import useLocales from '~/hooks/use_locales';
import Modal from '~/components/modal';
import useMst from '~/hooks/use_mst';
import { PERMISSIONS } from '~/mst/models/permissions/types';

import Filters from '~/components/filters';
import ButtonAdaptive from '~/components/adaptive_button';

import InviteUserForm from './invite_user_form';

function FilterBar() {
  const { invitations } = useMst();
  const { auth } = useMst();
  const { open, handleClose, handleOpen } = useModal();
  const { t } = useLocales();

  const isManager = auth?.user?.hasPermission(PERMISSIONS.ManageUsers);

  return (
    <Grid container spacing={2} sx={{ py: 2, px: 3 }}>
      <Grid item xs={12} sm={4} lg={6} md={6}>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <Filters collection={invitations} />
        </Stack>
      </Grid>
      {isManager && (
        <Grid item xs={12} sm={8} lg={6} md={6} sx={{ textAlign: 'right' }}>
          <ButtonAdaptive variant="contained" size="large" breakpoint="md" startIcon={<AddIcon />} onClick={handleOpen}>
            {t('users.invite_new_user')}
          </ButtonAdaptive>
          <Modal title={t('users.invite_new_user')} open={open} handleClose={handleClose}>
            <InviteUserForm />
          </Modal>
        </Grid>
      )}
    </Grid>
  );
}

export default FilterBar;
