import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';

import { SelectionProvider } from '~/hooks/react_grid/use_selection';
import { ExportProvider } from '~/hooks/react_grid/use_export';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';

import { PATHS } from '~/utils/constants';
import { ContainerGridStyled } from '~/components/react_grid/styled';
import Container from '~/components/container';
import useLocales from '~/hooks/use_locales';

import GridContainer from './grid_container';
import FormTop, { deviceFilterGroups } from './form_top';

function DevicesScreen() {
  const { t } = useLocales();
  const { nodes } = useMst();
  const { auth } = useMst();

  useFetch(nodes, { includes: ['profiles'] });

  useEffect(
    () =>
      autorun(async () => {
        if (nodes.isFetched && nodes.isFetching === false) {
          await nodes.fetchDataPoints({ last: 1, path: [PATHS.AQ, PATHS.ONLINE], organization_id: auth.organizationId });
          nodes.buildFilters(deviceFilterGroups);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <SelectionProvider>
      <ExportProvider>
        <Container>
          <Typography variant="h4" component="h1" paragraph>
            {t('devices.title')}
          </Typography>
          <ContainerGridStyled>
            <FormTop />
            <GridContainer />
          </ContainerGridStyled>
        </Container>
      </ExportProvider>
    </SelectionProvider>
  );
}

export default observer(DevicesScreen);
