/* eslint-disable no-param-reassign */
import type { IDeviceMonitor } from '.';
import { TABS } from './model';

export default (self: IDeviceMonitor) => ({
  setSelectedTab(tab) {
    self.selected_tab = tab;
  },
  setWidgets(widgets) {
    self.widgets.detach();
    self.widgets = widgets;
  },
  setDefaultTab() {
    self.selected_tab = TABS.GRAPH.name;
  },
  toggleParameter(dataPointId) {
    const params = `selected_params_${self.selected_tab}`;
    if (self.params[params]?.some((id) => id === dataPointId)) {
      const newParams = self.params[params].filter((id) => id !== dataPointId);
      self.params[params] = newParams;
      if (self.selected_tab === 'chart') {
        self.params.selected_params_table = newParams;
      }
    } else {
      self.params[params]?.push(dataPointId);
      if (self.selected_tab === 'chart') {
        self.params.selected_params_table?.push(dataPointId);
      }
    }
  }
});
