import React, { useCallback, useMemo } from 'react';
import { getSnapshot } from 'mobx-state-tree';
import pick from 'lodash/pick';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { observer } from 'mobx-react-lite';

import { useValidationSchema } from '~/hooks/use_validate';
import useNavigate from '~/hooks/use_navigate';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import toasts from '~/utils/toasts';
import type { IAlert } from '~/mst/models/alert';
import type { IAlertsStore } from '~/mst/models/alerts';
import { captureException } from '~/services/sentry';
import { processDataPoints, processSubscribers } from './form_helpers';
import FormSchema from './form_validation_schema';

import FormLayout from './form_layout';

function FormContainer({ model, alerts }: { model: IAlert; alerts: IAlertsStore }) {
  const { t } = useLocales();
  const { navigate } = useNavigate();
  const { auth } = useMst();

  const validate = useValidationSchema(FormSchema);

  const initialValues = useMemo(
    () => ({ ...getSnapshot(model), ...(model.isNew && auth.organizationId && { organization_id: auth.organizationId }) }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model.isFetched, auth.organizationId]
  );

  const handleOnSubmit = useCallback(
    async (values) => {
      try {
        const payload = {
          ...pick(values, 'name', 'description', 'organization_id'),
          _type: 'alert',
          subscribers: processSubscribers(values),
          data_points: processDataPoints(values.data_points, initialValues.data_points)
        };
        if (model.isNew) {
          await alerts.create(payload, model);
        } else {
          await model.update(payload);
        }
        toasts.success(t(`notifications.success.forms.${model.isNew ? 'create_alert' : 'update_alert'}`));
        navigate('alerts');
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [navigate, model, alerts, t, initialValues.data_points]
  );

  return (
    <FinalForm
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      validate={validate}
      subscription={{ submitting: true, pristine: true }}
    >
      {({ handleSubmit }) => <FormLayout handleSubmit={handleSubmit} model={model} />}
    </FinalForm>
  );
}

export default observer(FormContainer);
