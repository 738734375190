import { useCallback } from 'react';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import AddIcon from '@mui/icons-material/Add';
import ButtonDesktopOnly from '~/components/adaptive_button';
import useMst from '~/hooks/use_mst';
import { PERMISSIONS } from '~/mst/models/permissions/types';
import Filters from '~/components/filters';
import ActionsButton from '~/pages/devices/form_top/actions_button';
import { ActionsWrapperStyled } from '~/components/actions_dropdown/styled';
import useLocales from '~/hooks/use_locales';
import useNavigate from '~/hooks/use_navigate';

export const deviceFilterGroups = ['device-status', 'device-type', 'sensors'];

function ActionsContainer({ canManage }: { canManage: boolean }) {
  return <ActionsButton canManage={canManage} btnSize="large" showDownloadActions />;
}

function FormTop() {
  const { nodes } = useMst();
  const { auth } = useMst();
  const { t } = useLocales();
  const { navigate } = useNavigate();
  const canManage = auth?.user?.hasPermission(PERMISSIONS.ManageDevices);

  const handleRefresh = useCallback(async () => {
    nodes.fetch({ includes: ['profiles'], organization_id: auth.organizationId }, { noCache: true });
  }, [auth.organizationId, nodes]);

  return (
    <Grid container spacing={2} sx={{ py: 2, px: 3 }}>
      <Grid item xs={12} sm={4} lg={6} md={6}>
        <Filters collection={nodes} groups={deviceFilterGroups} handleRefresh={handleRefresh} />
      </Grid>

      <Grid item xs={12} sm={8} lg={6} md={6} sx={{ textAlign: 'right' }}>
        <ActionsWrapperStyled spacing={2} direction="row">
          {canManage && (
            <ButtonDesktopOnly
              aria-haspopup="true"
              variant="contained"
              size="medium"
              disableElevation
              onClick={() => navigate('devices/claim')}
              fullWidth
              breakpoint="sm"
            >
              <AddIcon fontSize="small" />
              {t('devices_page.claim')}
            </ButtonDesktopOnly>
          )}
          <ActionsContainer canManage={canManage} />
        </ActionsWrapperStyled>
      </Grid>
    </Grid>
  );
}

export default observer(FormTop);
