import React, { useState, useCallback } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { observer } from 'mobx-react-lite';

import ConfirmDialog from '~/components/confirm_dialog';

import useMst from '~/hooks/use_mst';
import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';
import { PERMISSIONS } from '~/mst/models/permissions/types';

import { PartnershipRowProps } from './types';

const ICON = {
  mr: 2,
  width: 20,
  height: 20
};

function ActionsFormatter({ row }: PartnershipRowProps) {
  const { organizationName, id, partnerName } = row;
  const { auth } = useMst();
  const { partnerships } = useMst();
  const partnership = partnerships.getById(id);

  const { t } = useLocales();

  const isManageable = auth?.user?.hasPermission(PERMISSIONS.ManageOrganizations, partnership?.organization.id);
  const { ActionsMenu, handleClose, handleOpen } = useActionsPopover();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = useCallback(
    async (event) => {
      event.stopPropagation();
      await partnerships.destroy(partnership);
      toasts.success(t('notifications.success.forms.delete_partnership'));
      setIsDeleting(false);
    },
    [partnerships, partnership, t, setIsDeleting]
  );

  const handleStartDelete = useCallback(
    (event) => {
      event.stopPropagation();
      setIsDeleting(true);
      handleClose();
    },
    [handleClose]
  );

  const handleCancelDelete = useCallback((event) => {
    event.stopPropagation();
    setIsDeleting(false);
  }, []);

  if (isManageable === false) {
    return null;
  }

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu sx={{ width: 200 }}>
        <MenuItem onClick={handleStartDelete} sx={{ color: 'error.main' }}>
          <DeleteIcon sx={ICON} />
          Delete
        </MenuItem>
      </ActionsMenu>
      <ConfirmDialog
        open={isDeleting}
        onClose={handleCancelDelete}
        onConfirm={handleDelete}
        title="Are you sure?"
        content={`Are you sure you want to delete "${organizationName} - ${partnerName}" partnership? You can't undo this action`}
        confirmLabel="Delete"
        confirmButtonType="error"
      />
    </>
  );
}

export default observer(ActionsFormatter);
