import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { computed } from 'mobx';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';

import useMst from '~/hooks/use_mst';
import DownloadDeviceCalibrationCertificateButton from '~/components/download_device_calibration_certificate_button';
import FormGrid from '~/components/@material-extend/form_grid';
import macAddressToHex from '~/utils/mac_hex';
import { BodyModule, ItemTitle } from '~/pages/device_edit/components';
import { ItemValueStyled } from '~/pages/device_edit/components/styled';
import useLocales from '~/hooks/use_locales';
import type { ValueLabelPair } from '~/mst/models/abstract/collection_types';
import { ISensor } from '~/mst/models/device/sensor';
import { formatDateFromTs } from '~/utils/format_date';

function Maintenance({ sensor }: { sensor: ISensor }) {
  const { t } = useLocales();
  const { auth } = useMst();

  const fields = useMemo(
    () =>
      computed(() => {
        const result: ValueLabelPair[] = [];
        if (sensor.calibration) {
          result.push({
            value: `${formatDateFromTs(sensor.calibration)} ${sensor.ccCalibration?.lastValue} ${sensor.ccCalibration?.unit}`,
            label: t('sensor.configuration.calibration')
          });
        }
        if (sensor.filterLastChanged) {
          result.push({
            value: formatDateFromTs(sensor.filterLastChanged),
            label: t('sensor.configuration.filter_last_changed')
          });
        }
        if (sensor.pumpRunTime) {
          result.push({
            value: `${sensor.pumpRunTime?.lastValue} ${sensor.pumpRunTime?.unit}`,
            label: t('sensor.configuration.pump_run_time')
          });
        }
        if (sensor.isThiamis === false && sensor.serial) {
          result.push({
            value: sensor.serial,
            label: t('attributes.node.serial')
          });
        }
        if (sensor.firmware) {
          result.push({
            value: sensor.firmware,
            label: t('sensor.configuration.firmware')
          });
        }
        if (sensor.iccid) {
          result.push({
            value: sensor.iccid,
            label: t('sensor.configuration.iccid')
          });
        }
        if (sensor.imei) {
          result.push({
            value: sensor.imei,
            label: t('sensor.configuration.imei')
          });
        }
        if (sensor.macAddress) {
          result.push({
            value: macAddressToHex(sensor?.macAddress),
            label: t('sensor.configuration.mac')
          });
        }

        return result;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  ).get();

  if (isEmpty(fields)) {
    return null;
  }

  return (
    <BodyModule title="Maintenance" columns={1} expandable>
      <FormGrid columns={2} rowGap={1}>
        {fields.map(({ label, value }) => (
          <Box>
            <ItemTitle>{label}</ItemTitle>
            <ItemValueStyled>{value}</ItemValueStyled>
          </Box>
        ))}
        {auth.user.isSupport && sensor?.hasCalibration && (
          <>
            <Box>
              <ItemTitle>{t('attributes.node.verification_date')}</ItemTitle>
              <ItemValueStyled>{sensor?.calibrationDate}</ItemValueStyled>
            </Box>
            <Box>
              <ItemTitle>{t('attributes.node.performance_verification')}</ItemTitle>
              <DownloadDeviceCalibrationCertificateButton node={sensor} />
            </Box>
          </>
        )}
      </FormGrid>
    </BodyModule>
  );
}

export default observer(Maintenance);
