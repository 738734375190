import { getIdentifier } from '~/mst/utils';
import isEmpty from 'lodash/isEmpty';
import type { IChartWidgetStore } from '.';

export default (self: IChartWidgetStore) => ({
  get data() {
    return (
      self.selected_data_point_ids
        .map((id) => self.nodes?.getNodeByDataPointId(id)?.data_points?.getById(id))
        .filter((dataPoint) => dataPoint)
        .map((dataPoint) => {
          const nodeName = self.nodes?.getNodeByDataPointId(getIdentifier(dataPoint))?.presentName;
          return {
            ...dataPoint,
            precision: dataPoint.precision || 0,
            presentName: self.node_id?.length === 1 ? dataPoint?.presentName : `${dataPoint?.presentName} (${nodeName})`
          };
        }) || []
    );
  },
  get nodeId() {
    return Array.isArray(self.node_id) ? self.node_id : [self.node_id];
  },
  get node() {
    return self.nodes?.getById(self.nodeId[0]);
  },
  get title() {
    return isEmpty(self.name) ? self.node_id?.map((id) => self.nodes?.getById(id)?.presentName).join(' | ') : self.name;
  }
});
