/* eslint-disable react/require-default-props */
import { useMemo } from 'react';
import { SxProps } from '@mui/material/styles';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery90Icon from '@mui/icons-material/Battery90';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import BatteryCharging30Icon from '@mui/icons-material/BatteryCharging30';
import BatteryCharging50Icon from '@mui/icons-material/BatteryCharging50';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';

import DeviceParameter from '~/components/device_parameter';

type BatteryContainerType = {
  value?: number | string;
  title: string;
  status: string;
  noValueText: string;
  unit?: string;
  iconLeft?: boolean;
  sx?: SxProps;
};

const getBatteryIcon = (value: number, status: string) => {
  if (status === 'charging') {
    if (value >= 90) return BatteryChargingFullIcon;
    if (value >= 80) return BatteryCharging90Icon;
    if (value >= 60) return BatteryCharging80Icon;
    if (value >= 50) return BatteryCharging60Icon;
    if (value >= 30) return BatteryCharging50Icon;
    if (value >= 20) return BatteryCharging30Icon;
    return BatteryCharging20Icon;
  }
  if (value >= 90) return BatteryFullIcon;
  if (value >= 80) return Battery90Icon;
  if (value >= 60) return Battery80Icon;
  if (value >= 50) return Battery60Icon;
  if (value >= 30) return Battery50Icon;
  if (value >= 20) return Battery30Icon;
  return Battery20Icon;
};

function BatteryContainer({ value, status, title, noValueText, unit, sx, iconLeft = false }: BatteryContainerType) {
  const BatteryLevel = useMemo(() => {
    const ToRender = getBatteryIcon(value, status);
    return <ToRender height="24px" sx={{ color: '#637381', width: 'auto', height: '24px', transform: 'rotate(90deg)' }} />;
  }, [value, status]);

  return (
    <DeviceParameter
      title={value ? `${value}${unit}` : ''}
      tooltipTitle={title}
      noValueText={noValueText}
      value={value}
      IconComponent={() => BatteryLevel}
      iconWidth="auto"
      iconHeight="24px"
      iconLeft={iconLeft}
      showLabel={false}
      sx={sx}
    />
  );
}

export default BatteryContainer;
