import React, { useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { getIdentifier } from '~/mst/utils';
import { computed } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import { Form as FinalForm } from 'react-final-form';
import { INode } from '~/mst/models/node';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';

import { captureException } from '~/services/sentry';
import Form from './form';

function AqmsContainer({ node }: { node: INode }) {
  const { t } = useLocales();
  const shedulerRef = useRef<HTMLInputElement>(null);
  const { templates } = useMst();

  useFetch(templates);

  const handleOnSubmit = useCallback(
    async (values) => {
      const { dfs, speed, automation, nodes, useSheduler } = values;
      try {
        if (!templates.aqms) {
          toasts.success(t('thiamis.aqms.no_template'));
        }
        let cronData = null;
        const sheduler = shedulerRef.current;
        if (sheduler && useSheduler) {
          const shedulerData = sheduler.getCurrentSchedule();
          if (!isEmpty(shedulerData)) {
            cronData = shedulerData;
          }
        }
        await node.thiamis.update({
          thiamis: { dfs, speed, automation, cron: cronData },
          templates: [
            {
              _id: getIdentifier(templates.aqms),
              configuration: {
                node_id: nodes.map((item) => (item.value ? item.value : item))
              }
            }
          ]
        });
        toasts.success(t(`notifications.success.forms.update_node`));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [node, t, templates.aqms]
  );

  const initialValues = useMemo(
    () =>
      computed(() => ({
        ...node?.thiamis?.toJSON(),
        useSheduler: Boolean(node?.thiamis?.cron),
        nodes: node?.thiamis?.nodeIds
      })),
    [node]
  ).get();

  return (
    <FinalForm onSubmit={handleOnSubmit} initialValues={initialValues} subscription={{ submitting: true, pristine: true, dirty: true }}>
      {({ handleSubmit }) => {
        return <Form handleSubmit={handleSubmit} model={node?.thiamis} shedulerRef={shedulerRef} />;
      }}
    </FinalForm>
  );
}

export default observer(AqmsContainer);
