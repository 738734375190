import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Stack } from '@mui/material';
import useSvgLoader from '~/hooks/use_svg_loader';
import DeviceLastUpdate from '~/components/device_last_update';

import { INode } from '~/mst/models/node';
import { IDevice } from '~/mst/models/device';

import DevicePower from '~/components/device_power';
import DeviceSignal from '~/components/device_signal';
import { StatusIconWithAutoUpdate } from '~/components/widgets/node_widget/content/name_formatter';
import { Icon, DeviceCard } from './components';

interface DeviceProps {
  device: IDevice;
  node: INode;
}

function DefaultDevice(props: DeviceProps) {
  const theme = useTheme();
  const { device = {}, node } = props;

  const SvgIcon = useSvgLoader(device?.name, 'DefaultDevice');

  return (
    <DeviceCard $device $centered>
      <Box
        sx={{
          top: theme.spacing(0.6),
          right: theme.spacing(0.7),
          zIndex: 9,
          position: 'absolute'
        }}
      />
      <Icon $fixheight>
        <SvgIcon />
      </Icon>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: theme.spacing(-0.25),
          paddingLeft: theme.spacing(1)
        }}
      >
        <Box>
          <Stack direction="row" alignItems="center">
            <StatusIconWithAutoUpdate model={node} size="xsmall" />
            <Typography variant="body2">
              <b>{device?.modelName}</b>
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} color={theme.palette.grey[900]} alignItems="center" mt={0.5} mb={0.5}>
            <DevicePower
              batteryLevel={node?.batteryLevel}
              batteryStatus={node?.batteryStatus}
              batteryCharge={node?.batteryCharge}
              isAirthinx={node?.isAirthinx}
            />
            <DeviceSignal
              networkSignal={node?.networkSignal}
              hasWifi={node?.hasWifi}
              networkName={node?.networkName}
              networkSignalUnit={node?.networkSignalUnit}
            />
            <DeviceLastUpdate lastTimeReportedTs={node?.data_points?.lastTimeReportedTs} />
          </Stack>
        </Box>
      </Box>
    </DeviceCard>
  );
}

export default observer(DefaultDevice);
