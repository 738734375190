import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import isNil from 'lodash/isNil';

import { IDataPoint } from '~/mst/models/data_point/base';
import ParamItem from './param_item';

function Params({ dataPoints, selectedColors }: { dataPoints: IDataPoint[]; selectedColors: any }) {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
      alignItems="center"
      justifyContent="center"
      spacing={{ xs: 0, sm: 2, md: 2, lg: 2, xl: 2 }}
      sx={{ marginTop: '1.5rem', flexWrap: 'wrap' }}
    >
      {dataPoints
        ?.filter((dp) => isNil(dp?.presentName) === false && dp?.presentName !== '')
        .map((dp) => <ParamItem key={dp.presentName} dataPoint={dp} color={selectedColors.get(dp._id)} />)}
    </Stack>
  );
}

export default observer(Params);
