import React, { useCallback, useState, useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useNavigate, useLocation } from 'react-router-dom';

import Yup from '~/utils/yup';
import { useValidationSchema } from '~/hooks/use_validate';
import { requestAccessContext } from '~/api/rest/auth';
import Modal from '~/components/modal';
import useMst from '~/hooks/use_mst';
import { observer } from 'mobx-react-lite';
import useCollapseDrawer from '~/hooks/use_collapse_drawer';
import { Icon } from '~/components/iconify';
import useLocales from '~/hooks/use_locales';

import { captureException } from '~/services/sentry';
import toasts from '~/utils/toasts';
import ModalForm from './modal_form';
import { ListItemTextStyled, ListItemIconStyled, ListItemStyled } from '../nav/styled';

function AccessContextModal() {
  const [open, setOpen] = useState<boolean>(false);
  const { auth } = useMst();
  const { users, organizations } = useMst();
  const { isCollapse } = useCollapseDrawer();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useLocales();

  const AccessContextSchema = Yup.object().shape({
    option: Yup.string().required().oneOf(['email', 'external']),
    email: Yup.string()
      .email()
      .when('option', {
        is: 'email',
        then: () => Yup.string().email().required('Email is required').typeError('Email is required'),
        otherwise: () => Yup.string().email().notRequired()
      }),
    external_id: Yup.string().when('option', {
      is: 'external',
      then: () => Yup.string().required('Organization is required'),
      otherwise: () => Yup.string().notRequired()
    })
  });

  const validate = useValidationSchema(AccessContextSchema);

  const showAccessContextModal = () => {
    setOpen(true);
  };

  const hideAccessContextModal = useCallback(() => {
    setOpen(false);
  }, []);

  const handleAccessContextSwitch = useCallback(
    async (values) => {
      try {
        const { email, external_id: externalId } = values;
        auth.startSyncing();
        await requestAccessContext({ email, external_id: externalId });
        auth.finishSyncing();
        await users.fetch(null, { noCache: true });
        hideAccessContextModal();
        await organizations.fetch(null, { noCache: true });
        const pagename = pathname.split('/').slice(2).join('/');
        navigate(`/${organizations.getOrganizationIdByExternalId(externalId)}/${pagename.trim() === '' ? 'home' : pagename}`);
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
        auth.failSyncing(e);
      }
    },
    [auth, navigate, hideAccessContextModal, users, organizations, pathname, t]
  );

  const initialValues = useMemo(
    () => ({
      option: 'email'
    }),
    []
  );

  const icon = Icon(AdminPanelSettingsIcon);

  return (
    <>
      <ListItemStyled $isCollapse={isCollapse} onClick={showAccessContextModal}>
        <ListItemIconStyled $isCollapse={isCollapse}>{icon}</ListItemIconStyled>
        <ListItemTextStyled disableTypography primary="Access Context" $isCollapse={isCollapse} />
      </ListItemStyled>
      <Modal open={open} handleClose={hideAccessContextModal} title="Design Access Context" size="md">
        <FinalForm onSubmit={handleAccessContextSwitch} validate={validate} initialValues={initialValues}>
          {({ handleSubmit }) => <ModalForm handleSubmit={handleSubmit} onClose={hideAccessContextModal} />}
        </FinalForm>
      </Modal>
    </>
  );
}

export default observer(AccessContextModal);
