import React from 'react';
import { observer } from 'mobx-react-lite';

import { Icon } from '@iconify/react';
import darkModeFill from '@iconify/icons-eva/moon-fill';
import lightModeFill from '@iconify/icons-eva/sun-fill';

import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import { THEMES } from '~/utils/constants';

import { IconWrapperStyled, MenuItemStyled } from './styled';

function ThemeMenuItem() {
  const { auth } = useMst();
  const { t } = useLocales();

  const newTheme = auth.theme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;
  const icon = newTheme === THEMES.LIGHT ? lightModeFill : darkModeFill;

  const toggleTheme = () => {
    auth.setTheme(newTheme);
  };

  return (
    <MenuItemStyled onClick={toggleTheme}>
      <IconWrapperStyled component={Icon} icon={icon} />
      {t(`theme.${newTheme}`)}
    </MenuItemStyled>
  );
}

export default observer(ThemeMenuItem);
