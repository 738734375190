import React from 'react';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { observer } from 'mobx-react-lite';
import type { IChat } from '~/mst/models/chat';
import Chat from './chat';

type StartThreadType = {
  model: null | IChat;
  onStart: () => void;
};

function StartThread({ onStart, model }: StartThreadType) {
  if (model?.isNew === false) {
    return <Chat model={model} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        px: 2,
        bgcolor: 'background.default'
      }}
    >
      <Typography variant="h4" gutterBottom>
        Analyze Your Environmental Report
      </Typography>
      <Typography variant="body1" sx={{ maxWidth: 600, mb: 4 }}>
        NoesisGPT AI assistant is here to help you analyze environmental reports. You can upload a report or provide details, and our assistant will
        provide insights, summaries, and actionable recommendations.
      </Typography>
      <LoadingButton loading={model?.isSyncing} variant="contained" size="large" onClick={onStart} sx={{ textTransform: 'none' }}>
        {model?.isNew ? 'Start' : 'Continue'} Conversation
      </LoadingButton>
      <Typography variant="body2" gutterBottom sx={{ pt: 2 }}>
        {model?.progress}
      </Typography>
    </Box>
  );
}

export default observer(StartThread);
