import { Instance, SnapshotOut } from 'mobx-state-tree';
import CollectionMapProcessor from '~/mst/models/abstract/collection_map_processor';
import model from './model';
import views from './views';
import actions from './actions';

const ThreadsStore = model.views(views).actions(actions);

export interface IThreadsStore extends Instance<typeof ThreadsStore> {}
export interface IThreadsStoreSnapshot extends SnapshotOut<typeof ThreadsStore> {}

export default CollectionMapProcessor(ThreadsStore);
