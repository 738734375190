import React, { useState, useCallback } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';
import ConfirmDialog from '~/components/confirm_dialog';
import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import { IToken } from '~/mst/models/token';
import useMst from '~/hooks/use_mst';
import { PERMISSIONS } from '~/mst/models/permissions/types';

function ActionsFormatter({ row }: { row: IToken }) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { auth } = useMst();
  const isManageable = auth?.user?.hasPermission(PERMISSIONS.ManageOrganizations, row?.organization_id);
  const { ActionsMenu, handleClose, handleOpen } = useActionsPopover();
  const { t } = useLocales();
  const handleStartDelete = useCallback(() => {
    handleClose();
    setIsDeleting(true);
  }, [handleClose]);

  const handleDeleteApi = useCallback(
    async (event) => {
      try {
        event.stopPropagation();
        await row.destroy();
        toasts.success(t('notifications.success.api_token_deleted'));
        setIsDeleting(false);
      } catch (e) {}
    },
    [row, t]
  );

  const handleCancelDelete = useCallback((event) => {
    event.stopPropagation();
    setIsDeleting(false);
  }, []);

  if (!isManageable) {
    return null;
  }

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu>
        <MenuItem sx={{ color: 'error.main' }} onClick={handleStartDelete}>
          <DeleteIcon />
          Delete
        </MenuItem>
      </ActionsMenu>
      <ConfirmDialog
        open={isDeleting}
        onClose={handleCancelDelete}
        onConfirm={handleDeleteApi}
        title={t('api.dialogs.delete.title')}
        content={t('api.dialogs.delete.content')}
        confirmLabel={t('base.buttons.delete')}
        confirmButtonType="error"
      />
    </>
  );
}

export default ActionsFormatter;
