import React, { useState, useCallback } from 'react';
import { IconButton, MenuItem, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { observer } from 'mobx-react-lite';

import ConfirmDialog from '~/components/confirm_dialog';
import useMst from '~/hooks/use_mst';
import useNavigate from '~/hooks/use_navigate';
import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';
import { PERMISSIONS } from '~/mst/models/permissions/types';

import { OrganizationRowProps } from './types';

const ICON = {
  mr: 2,
  width: 20,
  height: 20
};

function ActionsFormatter({ row }: OrganizationRowProps) {
  const { name, id } = row;
  const { auth } = useMst();
  const { organizations } = useMst();
  const organization = organizations.getById(id);

  const { t } = useLocales();

  const isManageable = auth?.user?.hasPermission(PERMISSIONS.ManageOrganizations, id);
  const { ActionsMenu, handleClose, handleOpen } = useActionsPopover();
  const [isDeleting, setIsDeleting] = useState(false);
  const { navigate } = useNavigate();

  const handleDelete = useCallback(
    async (event) => {
      event.stopPropagation();
      await organizations.destroy(organization);
      await auth.user?.fetch();
      toasts.success(t('notifications.success.forms.delete_organization'));
    },
    [organizations, organization, t, auth.user]
  );

  const handleModify = useCallback(
    (event) => {
      event.stopPropagation();
      navigate(`organizations/${id}`);
    },
    [navigate, id]
  );

  const handleStartDelete = useCallback(
    (event) => {
      event.stopPropagation();
      setIsDeleting(true);
      handleClose();
    },
    [handleClose]
  );

  const handleCancelDelete = useCallback((event) => {
    event.stopPropagation();
    setIsDeleting(false);
  }, []);

  if (!isManageable) {
    return null;
  }

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu sx={{ width: 200 }}>
        <MenuItem onClick={handleModify}>
          <EditIcon sx={ICON} />
          {t('base.buttons.edit')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleStartDelete} sx={{ color: 'error.main' }}>
          <DeleteIcon sx={ICON} />
          {t('base.buttons.delete')}
        </MenuItem>
      </ActionsMenu>
      <ConfirmDialog
        open={isDeleting}
        onClose={handleCancelDelete}
        onConfirm={handleDelete}
        title={t('organizations.dialogs.delete.title')}
        content={t('organizations.dialogs.delete.content', { name })}
        confirmLabel="Delete"
        confirmButtonType="error"
      />
    </>
  );
}

export default observer(ActionsFormatter);
