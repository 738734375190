import { Box, Typography } from '@mui/material';
import useMst from '~/hooks/use_mst';
import Image from '~/components/image';
import UserAvatar from '~/components/user_avatar';
import ProfileCoverImg from '~/assets/images/profile_cover.png';
import { InfoStyled, ProfileCoverRootStyled } from './styled';

export default function ProfileCover() {
  const {
    auth: { user }
  } = useMst();

  return (
    <ProfileCoverRootStyled>
      <InfoStyled>
        <UserAvatar
          user={user}
          sx={{
            mx: 'auto',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'common.white',
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 }
          }}
        />
        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: 'common.white',
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          <Typography variant="h4">{user.fullName}</Typography>
        </Box>
      </InfoStyled>
      <Image alt="profile cover" src={ProfileCoverImg} sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
    </ProfileCoverRootStyled>
  );
}
