import { Portal } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import compact from 'lodash/compact';

import { IconButtonAnimate } from '~/components/animate';
import ChatModel from '~/mst/models/chat';
import useMst from '~/hooks/use_mst';
import toasts from '~/utils/toasts';
import { Iconify } from '~/components/iconify';
import useLocales from '~/hooks/use_locales';
import { computed } from 'mobx';
import { persistedStore } from '~/mst/store';
import AnalyticsModel from '~/mst/models/analytics';
import PanelStyled, { PanelInnerStyled } from './styled';
import StartThread from './start_thread';

type GptContainerType = {
  isVisible: boolean;
  reportId: string;
  onHide: () => void;
};

function GptContainer({ isVisible, onHide, reportId }: GptContainerType) {
  const { reports, chats } = useMst();
  const report = reports.getById(reportId);

  const chat = useMemo(
    () =>
      computed(() => {
        if (report?.chatId && persistedStore.isRehydrated) {
          return chats.getById(report?.chatId) || chats.add(ChatModel.create({ id: report?.chatId }));
        }
        return null;
      }),
    [chats, report?.chatId]
  ).get();

  const { t } = useLocales();
  const analytics = useMemo(() => AnalyticsModel.create({}), []);

  const handleStartThread = useCallback(async () => {
    try {
      if (chat.isNew) {
        chat.setProgress('Fetching report data...');
        await analytics.fetch(report?.configuration?.toJSON());
        await chats.create(chat, {
          name: `NoesisGPT AI assistant for "${report?.name}" report`,
          data: JSON.stringify(analytics?.dataWithDevices, null, 2),
          parameters: report?.configuration.parameters,
          devices: compact(report?.configuration.node_ids.map((id) => analytics.nodes.getById(id)?.presentName)),
          metadata: {
            updated_at: report?.updatedAtIso
          }
        });
      }
    } catch (e) {
      toasts.error(t('notifications.errors.server_error'));
    }
  }, [report?.name, report?.configuration, analytics, report?.updatedAtIso, chat, chats, t]);

  return (
    <Portal>
      <PanelStyled $isVisible={isVisible}>
        <PanelInnerStyled>
          <IconButtonAnimate onClick={onHide} sx={{ position: 'absolute', top: 5, right: 10, zIndex: 90 }}>
            <Iconify icon="eva:close-fill" width={20} height={20} />
          </IconButtonAnimate>
          <StartThread onStart={handleStartThread} model={chat} />
        </PanelInnerStyled>
      </PanelStyled>
    </Portal>
  );
}

export default observer(GptContainer);
