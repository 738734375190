import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';
import actions from './actions';

const Message = model.views(views).actions(actions);

export interface IMessage extends Instance<typeof Message> {}

export default Message;
