/* eslint-disable no-param-reassign */
import { flow, getParent } from 'mobx-state-tree';
import { getIdentifier } from '~/mst/utils';
import api from '~/api';
import type { ITokensModel } from '~/mst/models/tokens/model';
import type { ITokenModel } from './model';

export default (self: ITokenModel) => ({
  fetch: flow(function* fetch() {
    try {
      self.startFetching();
      const { data } = yield api.tokens.read(self.id);
      self.external_id_revealed = data.external_id;
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  destroy: flow(function* destroy() {
    self.startSyncing();
    try {
      yield api.tokens.destroy(getIdentifier(self)!);
      const tokens = getParent<ITokensModel>(self, 2);
      tokens.remove(self);
    } catch (error) {
      self.failSyncing(error);
    }
  })
});
