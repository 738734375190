/* eslint-disable @typescript-eslint/naming-convention */
import { getStore } from '~/mst/utils';
import { IThreadModel } from './model';

export default (self: IThreadModel) => ({
  get searchString() {
    const { name, description } = self;
    return [name, description].join(' ').toLowerCase();
  },
  get isBlue() {
    return self.name === 'Airthinx Blue';
  },
  get isAqms() {
    return self.name === 'aqms_default';
  },
  get owner() {
    return getStore(self).organizations.getById(self.organization_id);
  }
});
