/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import { getIdentifier, toMapById } from '~/mst/utils';
import api from '~/api';
import type { IWebhooksModel } from './model';

export default (self: IWebhooksModel) => ({
  fetch: flow(function* fetch(params, options) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.webhooks.fetch, params, options);
      applySnapshot(self.models, toMapById(data));
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  remove(webhook) {
    self.models.delete(getIdentifier(webhook)!);
  },
  create: flow(function* create(payload, model) {
    model.startSyncing();
    try {
      const { data } = yield api.webhooks.create(payload);
      self.models.put(data);
      model.finishSyncing();
    } catch (error) {
      model.failSyncing(error);
    }
  })
});
