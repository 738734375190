/* eslint-disable @typescript-eslint/naming-convention */
import { getIdentifier } from '~/mst/utils';
import type { IOrganization } from '~/mst/models/organization';
import type { IOrganizationsModel } from './model';

export default (self: IOrganizationsModel) => ({
  get valueLabelPairsIsMember() {
    return self.valueLabelPairs.filter(({ model }) => model.is_member);
  },
  get valueLabelPairsIsMemberWithAll() {
    const options = self.valueLabelPairsIsMember;
    if (options.length > 1) {
      return [{ value: 'all', label: 'All' }, ...options];
    }
    return options;
  },
  get valueLabelPairsManagerAccess() {
    return self.valueLabelPairs.filter(({ model }) => model.hasManagerAccess);
  },
  get valueLabelPairsCanManageReports() {
    return self.valueLabelPairs.filter(({ model }) => model.canManageReports);
  },
  get valueLabelPairsCanReadReports() {
    return self.valueLabelPairs.filter(({ model }) => model.canReadReports);
  },
  getOrganizationIdByExternalId(id?: string) {
    const organization = self.toArray.find(({ external_id }) => external_id.toLowerCase() === id?.toLowerCase());
    if (organization) {
      return getIdentifier(organization);
    }
    return null;
  },
  get valueLabelPairsExternalId() {
    return self.valueLabelPairs.map(({ label, model }) => ({
      label,
      value: model.external_id
    }));
  },
  get filteredList() {
    return self.filteredModels
      .filter((organizatiion) => organizatiion.is_member)
      .map((organization: IOrganization) => ({
        id: organization.id,
        isSyncing: organization.isSyncing,
        is_locked: organization.is_locked,
        isDisabled: organization.is_locked,
        name: organization.name,
        location: organization.shippingLocation,
        created_at: organization.created_at,
        stats: organization.stats.groupByType,
        external_id: organization.external_id
      }));
  }
});
