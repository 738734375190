import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ButtonDesktopOnly from '~/components/adaptive_button';

import Breadcrumbs from '~/components/breadcrumbs';
import LoadingBox from '~/components/loading_box';
import Container from '~/components/container';
import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import ReportContent from './content';
import Actions from './actions';

function ReportView() {
  const { id } = useParams();
  const { t } = useLocales();
  const { reports } = useMst();
  const { isFetching } = useFetch(reports);

  const report = reports.getById(id);

  const links = useMemo(
    () => [
      { title: t('reports.title'), to: 'reports' },
      [
        { title: t('reports.edit_details_breadcrumb'), to: `reports/${id}` },
        { title: t('reports.view_details_breadcrumb'), to: `reports/${id}/view` }
      ]
    ],
    [id, t]
  );

  return (
    <Container>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
        <Breadcrumbs links={links} />
        <Stack direction="row" gap={1}>
          <ButtonDesktopOnly
            sx={{ height: '36px' }}
            aria-haspopup="true"
            variant="outlined"
            size="medium"
            disableElevation
            onClick={() => {}}
            startIcon={<AutoAwesomeIcon />}
            fullWidth
            breakpoint="sm"
          >
            NoesisGPT
          </ButtonDesktopOnly>
          <Actions token={report?.external_id} />
        </Stack>
      </Stack>
      {isFetching ? <LoadingBox /> : <ReportContent token={report?.external_id} name={report?.name} />}
    </Container>
  );
}

export default observer(ReportView);
