/* eslint-disable react/require-default-props */
import { useCallback } from 'react';
import { CardActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useLocales from '~/hooks/use_locales';
import TextInput from '~/components/final_form/text_input';
import FormGrid from '~/components/@material-extend/form_grid';
import ImageUpload from '~/components/final_form/image_upload';
import { IOrganization } from '~/mst/models/organization';
import { observer } from 'mobx-react-lite';
import toasts from '~/utils/toasts';
import { captureException } from '~/services/sentry';
import useMst from '~/hooks/use_mst';

type FormType = {
  model: IOrganization;
  handleSubmit: VoidFunction;
};

function Form({ model, handleSubmit }: FormType) {
  const { t } = useLocales();
  const { auth } = useMst();

  const handleToggleLock = useCallback(async () => {
    try {
      if (model.is_locked) {
        await model.unlock();
        toasts.success(t('notifications.success.organization_unlocked'));
      } else {
        await model.lock();
        toasts.success(t('notifications.success.organization_locked'));
      }
    } catch (e) {
      captureException(e);
      toasts.error(t('notifications.errors.server_error'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.is_locked, t]);

  return (
    <>
      <FormGrid columns="2">
        <ImageUpload name="logo" />
        <TextInput name="name" label={t('attributes.organization.fullname')} />
      </FormGrid>
      <CardActions>
        {auth.user.isSupport && (
          <LoadingButton
            type="button"
            disabled={model.isSyncing}
            variant="contained"
            color="error"
            loading={model.isSyncing}
            onClick={handleToggleLock}
          >
            {model.is_locked ? t('base.buttons.unlock') : t('base.buttons.lock')}
          </LoadingButton>
        )}
        <LoadingButton type="submit" disabled={model.isSyncing} variant="contained" loading={model.isSyncing} onClick={handleSubmit}>
          {model.isNew ? t('base.buttons.create_new') : t('base.buttons.save_changes')}
        </LoadingButton>
      </CardActions>
    </>
  );
}

export default observer(Form);
