import { Instance, SnapshotOut } from 'mobx-state-tree';
import model from './model';
import actions from './actions';

const ChatsStore = model.actions(actions);

export interface IChatsStore extends Instance<typeof ChatsStore> {}
export interface IChatsStoreSnapshot extends SnapshotOut<typeof ChatsStore> {}

export default ChatsStore;
