import { getIdentifier } from '~/mst/utils';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import flatten from 'lodash/fp/flatten';
import sortBy from 'lodash/fp/sortBy';
import { STATUS_WEIGHTS } from '~/utils/constants';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import type { INodeWidget } from '.';

export default (self: INodeWidget) => {
  function getDataPoints(node) {
    return node?.data_points?.defaultValueLabelPairsSortedByStatus
      .filter(({ model }) => {
        if (self.selected_data_point_ids.includes('all') || isEmpty(self.selected_data_point_ids)) {
          return true;
        }
        return self.selected_data_point_ids.includes(getIdentifier(model));
      })
      ?.map(({ model }) => model);
  }
  return {
    get nodeId() {
      return Array.isArray(self.node_id) ? self.node_id : [self.node_id];
    },
    get node() {
      return self.nodes?.getById(self.nodeId[0]);
    },
    get data() {
      if (self.nodeId.length === 1) {
        return getDataPoints(self.node);
      }
      return flow(
        map((node) => ({
          key: getIdentifier(node),
          items: getDataPoints(node).filter((dp) => {
            if (self.filter) {
              return dp.statusType() === self.filter;
            }
            return true;
          })
        })),
        filter(({ items }) => items.length),
        sortBy(({ key }) => self.nodes?.getById(key)?.name)
      )(self.nodes?.toArray);
    },
    get legend() {
      return flow(
        map((id) => getDataPoints(self.nodes?.getById(id))),
        flatten,
        compact,
        filter((dp) => dp?.hasMeeasurements),
        map((dp) => dp.statusType()),
        uniq,
        sortBy((a, b) => STATUS_WEIGHTS[b] - STATUS_WEIGHTS[a])
      )(self.node_id);
    }
  };
};
