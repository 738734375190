/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import NodesStore from '~/mst/models/nodes';
import DataPointsStore from '~/mst/models/data_points/node';

const AnalyticsModel = t.compose(
  t.model({
    nodes: t.optional(NodesStore, []),
    data_points: t.optional(DataPointsStore, []),
    selected_parameters: t.array(t.string),
    promt: t.frozen(),
    response: t.frozen()
  }),
  Syncable,
  Fetchable()
);

export interface IAnalyticsModel extends Instance<typeof AnalyticsModel> {}

export default AnalyticsModel;
