import { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import isEmpty from 'lodash/isEmpty';
import { Tooltip } from '@mui/material';
import useLocales from '~/hooks/use_locales';
import SyncIcon from '@mui/icons-material/Sync';
import type { IFilterable } from '~/mst/models/abstract/filterable';
import useMst from '~/hooks/use_mst';
import { FilterButtonWrapperStyled, FilterButtonStyled, BadgeContainerStyled } from '../styled';
import FiltersPickerContainer from './container';
import { BadgeStyled, CloseIconStyled, FilterListIconStyled } from './styled';

function FilterBadge({ handleResetFilters }: { handleResetFilters: VoidFunction }) {
  return (
    <BadgeContainerStyled direction="row" alignItems="center" onClick={handleResetFilters}>
      Clear <CloseIconStyled sx={{ fontSize: 16 }} />
    </BadgeContainerStyled>
  );
}

type FiltersPickerProps = {
  collection: IFilterable;
  handleRefresh?: VoidFunction;
  sorting: {
    [key: string]: number;
  };
};
function FiltersPicker({ collection, sorting, handleRefresh }: FiltersPickerProps) {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useLocales();
  const { auth } = useMst();

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isVisible]);

  const handleChange = useCallback(
    (filter) => {
      collection.toggleFilter(filter);
    },
    [collection]
  );

  const handleRefreshDefault = useCallback(
    () => collection.fetch({ organization_id: auth.organizationId }, { noCache: true }),
    [collection, auth.organizationId]
  );

  const handleResetFilters = useCallback(() => {
    collection.clearFilters();
  }, [collection]);

  const handleVisible = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  return (
    <>
      <FilterButtonWrapperStyled direction="row" gap={1}>
        {isEmpty(collection.filterGroups) === false && (
          <BadgeStyled
            invisible={!collection.hasChosenFilters}
            badgeContent={<FilterBadge handleResetFilters={handleResetFilters} />}
            color="primary"
          >
            <Tooltip title={t('filters.tooltip')} placement="top">
              <FilterButtonStyled variant="text" color="secondary" onClick={handleVisible}>
                <FilterListIconStyled />
              </FilterButtonStyled>
            </Tooltip>
          </BadgeStyled>
        )}
        <Tooltip title={t('device_monitor.reload_data')} placement="top">
          <FilterButtonStyled
            variant="text"
            color="secondary"
            onClick={handleRefresh || handleRefreshDefault}
            size="small"
            disabled={collection.isFetching}
          >
            <SyncIcon color="primary" />
          </FilterButtonStyled>
        </Tooltip>
      </FilterButtonWrapperStyled>
      {isEmpty(collection.filterGroups) === false && (
        <FiltersPickerContainer
          isVisible={isVisible}
          onHide={handleVisible}
          onChange={handleChange}
          groups={collection.filterGroups}
          sorting={sorting}
        />
      )}
    </>
  );
}

export default observer(FiltersPicker);
