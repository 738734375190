/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import { getIdentifier } from '~/mst/utils';
import api from '~/api';
import { IDataPointModel } from './model';

export default (self: IDataPointModel) => {
  function resetMeasurements(newMeasurements = []) {
    self.measurements = newMeasurements;
  }
  return {
    updateMeasurements(newMeasurements = []) {
      self.measurements = self.measurements.concat(newMeasurements);
    },
    resetMeasurements,
    fetch: flow(function* fetch(params: any, options: any) {
      try {
        self.startFetching();
        const dataPointId = getIdentifier(self);
        const payload = { data_point_id: dataPointId, ...params };
        const { data } = yield self.sync(api.search.measurements, payload, options);
        resetMeasurements(data[dataPointId!]);
        self.finishFetching();
      } catch (error) {
        self.failFetching(error);
      }
    })
  };
};
