import { Instance, SnapshotOut } from 'mobx-state-tree';
import CollectionMapProcessor from '~/mst/models/abstract/collection_map_processor';
import model from './model';
import views from './views';
import actions from './actions';

const AssistantsStore = model.views(views).actions(actions);

export interface IAssistantsStore extends Instance<typeof AssistantsStore> {}
export interface IAssistantsStoreSnapshot extends SnapshotOut<typeof AssistantsStore> {}

export default CollectionMapProcessor(AssistantsStore);
