import { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material';
import { autorun } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsExporting from 'highcharts/modules/exporting';
import debounce from 'lodash/debounce';

import useChart from '~/hooks/use_chart';
import useLocales from '~/hooks/use_locales';

import { IDataPointsNode } from '~/mst/models/data_points/node';

import { getChartOptions, renderAxies, renderSeries, renderXAxis, defaultChartOptions } from './chart_options';
import ChartWrapperStyled from './styled';

NoDataToDisplay(Highcharts);
HighchartsExporting(Highcharts);
Highcharts.setOptions({
  time: {
    useUTC: false
  }
});

function Chart({
  data = [],
  isLoading,
  options,
  height,
  showZones,
  showLabels = true,
  showDataGaps
}: {
  data: IDataPointsNode;
  isLoading?: boolean;
  showZones?: boolean;
  height?: string;
  showDataGaps?: boolean;
  showLabels: boolean;
  options: any;
}) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const containerRef = useRef(null);
  const [chartOptions, setChartOptions] = useState(defaultChartOptions);
  const theme = useTheme();
  const { t } = useLocales();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listener = useCallback(() => chartRef.current?.chart.reflow(), []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const mediaQueryList = window.matchMedia('print');
    try {
      mediaQueryList.addEventListener('change', listener);
      return () => {
        mediaQueryList.removeEventListener('change', listener);
      };
    } catch (e) {}
  }, [listener]);

  useEffect(
    () =>
      autorun(() => {
        const newChartOptions = getChartOptions(
          {
            ...options,
            xAxis: renderXAxis(theme.palette.mode, showDataGaps),
            yAxis: renderAxies(theme.palette.mode, data, showZones, showLabels),
            series: renderSeries(theme.palette.mode, data, showZones, showDataGaps)
          },
          theme.palette.mode
        );

        setChartOptions(newChartOptions);
      }),
    [data, showZones, options, showDataGaps, showLabels, theme.palette.mode]
  );

  const resizeObserver = useRef(
    new ResizeObserver(
      debounce(() => {
        if (chartRef.current) {
          chartRef.current.chart.reflow();
        }
      }, 250)
    )
  );

  const { setChartRef } = useChart();

  useEffect(() => {
    if (chartRef.current) {
      setChartRef(chartRef);
    }
  }, [chartRef, setChartRef]);

  useEffect(() => {
    const resizeObserverInstance = resizeObserver.current;
    const containerRefInstance = containerRef.current;
    if (containerRefInstance) {
      resizeObserverInstance.observe(containerRefInstance);
    }
    return () => {
      if (containerRefInstance) {
        resizeObserverInstance.unobserve(containerRefInstance);
      }
    };
  }, [containerRef, resizeObserver]);

  useEffect(() => {
    if (chartRef.current) {
      if (isLoading) {
        chartRef.current.chart.hideNoData();
        chartRef.current.chart.showLoading();
      } else {
        chartRef.current.chart.hideNoData();
        chartRef.current.chart.hideLoading();
        if (data.length === 0) {
          chartRef.current.chart.showNoData(t('dashboard.chart_no_parameters_selected'));
        } else if (data.every((dp) => isEmpty(dp?.measurements))) {
          chartRef.current.chart.showNoData(t('dashboard.chart_no_data'));
        } else {
          chartRef.current.chart.hideNoData();
        }
      }
    }
  }, [isLoading, data, t]);

  return (
    <ChartWrapperStyled ref={containerRef}>
      {chartOptions && (
        <HighchartsReact
          ref={chartRef}
          constructorType="stockChart"
          highcharts={Highcharts}
          containerProps={{ className: 'highchart-overflow_wrapper', style: { height } }}
          options={chartOptions}
        />
      )}
    </ChartWrapperStyled>
  );
}

export default Chart;
