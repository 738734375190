import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Paper, CircularProgress, Stack, IconButton, Typography } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import { LoadingButton } from '@mui/lab';
import { autorun } from 'mobx';
import { Iconify } from '~/components/iconify';
import { observer } from 'mobx-react-lite';
import type { IChat } from '~/mst/models/chat';
import useLocales from '~/hooks/use_locales';
import toasts from '~/utils/toasts';

import Message from './message';
import TextDelta from './text_delta';

type ChatType = {
  model: IChat;
};

function Chat({ model }: ChatType) {
  const [input, setInput] = useState('');
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const { t } = useLocales();

  useEffect(
    () =>
      autorun(() => {
        if (!model.messages.isFetching && !model.messages.isFetched) {
          model.messages.fetch(model.thread_id);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model.messages]
  );

  useEffect(
    () =>
      autorun(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model.sortedMessages]
  );

  const handleSendMessage = async () => {
    if (input.trim()) {
      setInput('');
      await model.createMessage(input.trim());
    }
  };

  const handleDelete = useCallback(async () => {
    try {
      await model.destroy();
      toasts.success(t('notifications.success.chat_assistant_deleted'));
    } catch (e) {
      toasts.error(t('notifications.errors.server_error'));
    }
  }, [model, t]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: '16px'
      }}
    >
      <Typography variant="subtitle1" gutterBottom alignSelf="flex-start" sx={{ position: 'absolute', top: 15, left: 20 }}>
        {model?.name || 'NoesisGPT AI assistant'}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '85vh',
          width: '100%',
          padding: '16px',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Paper
          sx={{
            border: 0,
            boxShadow: 'none',
            flexGrow: 1,
            overflowY: 'auto',
            padding: '16px',
            marginBottom: '16px'
          }}
          elevation={3}
        >
          <>
            {model.sortedMessages.map((message) => (
              <Message key={message.id} message={message} />
            ))}
            {model.thinking ? (
              <Stack direction="row" justifyContent="center">
                <CircularProgress size={25} color="info" />
              </Stack>
            ) : (
              <TextDelta model={model} />
            )}
            <div ref={messagesEndRef} />
          </>
        </Paper>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            textarea: {
              padding: '16px',
              width: '100%',
              fontFamily: 'Roboto, sans-serif',
              fontSize: '16px',
              color: '#333',
              lineHeight: '24px',
              borderColor: '#dfdfdf',
              borderRadius: '8px'
            }
          }}
        >
          <TextareaAutosize
            minRows={3}
            placeholder="Type your message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <Box sx={{ position: 'absolute', right: 5, bottom: 5 }}>
            <IconButton color="primary" variant="contained" onClick={handleSendMessage}>
              {model.thinking ? <CircularProgress size={25} color="info" /> : <Iconify icon="eva:arrow-circle-up-fill" width={40} height={40} />}
            </IconButton>
          </Box>
        </Box>
      </Box>
      <LoadingButton variant="outlined" color="error" loading={model?.isSyncing} onClick={handleDelete} sx={{ position: 'absolute', bottom: 20 }}>
        Delete Assistant
      </LoadingButton>
    </Box>
  );
}

export default observer(Chat);
