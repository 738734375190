import alerts_pages from './alerts_pages.json';
import api_page from './api_page.json';
import api_api_page from './api_api_page.json';
import webhooks_page from './webhooks_page.json';
import dashboard_page from './dashboard_page.json';
import filters from './filters.json';
import firmwares_page from './firmwares_page.json';
import formation_requests_page from './formation_requests_page.json';
import header from './header.json';
import login_page from './login_page.json';
import messages_page from './messages_page.json';
import models from './models.json';
import navigation from './navigation.json';
import notifications from './notifications.json';
import organizations_page from './organizations_page.json';
import partnership_invitations_page from './partnership_invitations_page.json';
import partnerships_page from './partnerships_page.json';
import thiamis_pages from './thiamis_pages.json';
import user_list_page from './user_list_page.json';
import user_invitations_page from './user_invitations_page.json';
import user_profile_page from './user_profile_page.json';
import users_pages from './users_pages.json';
import context_page from './context_page.json';
import views from './views.json';
import widgets from './widgets.json';
import certificates from './certificates_pages.json';
import healthway from './healthway.json';
import map from './map.json';
import dashboards from './dashboards.json';
import permissions from './permissions.json';
import automations from './automations.json';
import dialogs from './dialogs.json';
import invitations_page from './invitations_page.json';
import home_page from './home_page.json';
import archives_page from './archives_page.json';
import device_monitor_page from './device_monitor_page.json';
import reports from './reports_pages.json';
import calibrations from './calibrations_page.json';
import analytics from './analytics_page.json';

const translations = {
  ...navigation,
  ...models,
  ...views,
  ...alerts_pages,
  ...certificates,
  ...api_page,
  ...api_api_page,
  ...webhooks_page,
  ...dashboard_page,
  ...filters,
  ...firmwares_page,
  ...formation_requests_page,
  ...header,
  ...login_page,
  ...messages_page,
  ...notifications,
  ...organizations_page,
  ...partnership_invitations_page,
  ...partnerships_page,
  ...thiamis_pages,
  ...user_list_page,
  ...user_invitations_page,
  ...user_profile_page,
  ...context_page,
  ...users_pages,
  ...widgets,
  ...healthway,
  ...map,
  ...permissions,
  ...dashboards,
  ...automations,
  ...dialogs,
  ...invitations_page,
  ...home_page,
  ...archives_page,
  ...device_monitor_page,
  ...reports,
  ...calibrations,
  ...analytics
};

export default translations;
