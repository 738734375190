import React, { useCallback, useState } from 'react';
import { IconButton, MenuItem, Divider, Box, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import AddIcon from '@mui/icons-material/Settings';
import ViewIcon from '@mui/icons-material/BarChart';

import toasts from '~/utils/toasts';
import useMst from '~/hooks/use_mst';
import ConfirmDialog from '~/components/confirm_dialog';
import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import useNavigate from '~/hooks/use_navigate';
import useLocales from '~/hooks/use_locales';

const ICON = {
  mr: 2,
  width: 20,
  height: 20
};

function ResetDialogContent({ serial, name }: { serial: string; name: string }) {
  const { t } = useLocales();
  return (
    <Box sx={{ pt: 1 }}>
      <Typography>{t('thiamis.manage_dialog.reset_confirm', { name, serial })}</Typography>
      <Box sx={{ pt: 3 }}>
        <Typography variant="small">{t('thiamis.manage_dialog.reset_help_text')}</Typography>
      </Box>
    </Box>
  );
}

function ActionsFormatter(props: any) {
  const { row = {} } = props || {};
  const { isCreatable, isMonitorable, isDeletable, isModifiable, organizationId, _id: nodeId, serial, name } = row;
  const { auth } = useMst();
  const { nodes } = useMst();
  const { t } = useLocales();

  const { ActionsMenu, handleClose, handleOpen } = useActionsPopover();
  const { navigate } = useNavigate();

  const handleModify = useCallback(() => {
    navigate(`devices/${nodeId}/edit`);
  }, [navigate, nodeId]);

  const isAdmin = auth.hasAdminDevice(organizationId);

  const handleAdmin = useCallback(() => {
    navigate(`devices/${nodeId}/admin`);
  }, [navigate, nodeId]);

  const handleMonitor = useCallback(() => {
    navigate(`devices/${nodeId}/monitor`);
  }, [navigate, nodeId]);

  const handleCreateConfiguration = useCallback(() => {
    navigate(`devices/${nodeId}/create`);
  }, [navigate, nodeId]);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleReset = useCallback(async () => {
    await nodes.getById(nodeId).reset();
    setIsDeleting(false);
    nodes.fetch({ includes: ['profiles'], organization_id: auth.organizationId }, { noCache: true });
    toasts.success(t('thiamis.manage_dialog.reset_success', { serial }));
  }, [nodeId, nodes, setIsDeleting, t, serial, auth.organizationId]);

  const handleStartReset = useCallback(
    (event) => {
      event.stopPropagation();
      setIsDeleting(true);
      handleClose();
    },
    [handleClose]
  );

  const handleCancelReset = useCallback((event) => {
    event.stopPropagation();
    setIsDeleting(false);
  }, []);

  return (
    <>
      {(isModifiable || isDeletable || isMonitorable || isCreatable) && (
        <IconButton onClick={handleOpen}>
          <MoreVertIcon />
        </IconButton>
      )}
      <ActionsMenu>
        {isCreatable && (
          <MenuItem onClick={handleCreateConfiguration}>
            <AddIcon sx={ICON} />
            {t('base.buttons.create')}
          </MenuItem>
        )}
        {isMonitorable && (
          <MenuItem onClick={handleMonitor}>
            <ViewIcon sx={ICON} />
            {t('base.buttons.view')}
          </MenuItem>
        )}
        {isModifiable && (
          <MenuItem onClick={handleModify}>
            <EditIcon sx={ICON} />
            {t('base.buttons.edit')}
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem onClick={handleAdmin}>
            <AdminIcon sx={ICON} />
            {t('base.buttons.admin')}
          </MenuItem>
        )}
        {isDeletable && (
          <>
            <Divider />
            <MenuItem onClick={handleStartReset} sx={{ color: 'error.main' }}>
              <DeleteIcon sx={ICON} />
              {t('base.buttons.reset')}
            </MenuItem>
          </>
        )}
      </ActionsMenu>
      <ConfirmDialog
        open={isDeleting}
        onClose={handleCancelReset}
        onConfirm={handleReset}
        title={t('thiamis.delete_thiamis')}
        content={<ResetDialogContent serial={serial} name={name} />}
        confirmLabel={t('base.buttons.reset')}
        confirmButtonType="error"
      />
    </>
  );
}

export default ActionsFormatter;
