/* eslint-disable no-param-reassign */
import { IMessageModel } from './model';

export default (self: IMessageModel) => ({
  setContent(content: string) {
    self.content = [
      {
        text: {
          value: content
        }
      }
    ];
  }
});
