/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import { getIdentifier, toMapById } from '~/mst/utils';
import api from '~/api';
import type { IInvitation } from '~/mst/models/invitation';
import type { IInvitationsModel } from './model';

export default (self: IInvitationsModel) => ({
  fetch: flow(function* fetch(params = {}, options = {}) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.invitations.fetch, params, options);
      applySnapshot(self.models, toMapById(data, 'id'));
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  destroy: flow(function* destroy(model) {
    model.startSyncing();
    try {
      yield api.invitations.destroy(getIdentifier(model)!);
      self.remove(model);
    } catch (error) {
      model.failSyncing(error);
    }
  }),
  create: flow(function* create(payload, model: IInvitation) {
    try {
      model.startSyncing();
      yield api.invitations.create(payload);
      model.finishSyncing();
    } catch (error) {
      model.failSyncing(error);
    }
  })
});
