/* eslint-disable @typescript-eslint/naming-convention */
import { getParent } from 'mobx-state-tree';
import { getIdentifier } from '~/mst/utils';
import type { IWidget } from '..';
import type { ILayoutModel } from './model';

export default (self: ILayoutModel) => {
  function getDefaultLayout() {
    const widget = getParent<IWidget>(self);
    return {
      i: getIdentifier(widget),
      x: 0,
      y: 0,
      w: 4,
      h: 3,
      isBounded: true,
      minH: 3,
      minW: 4
    };
  }
  return {
    get defaultLayout() {
      return getDefaultLayout();
    },
    get breakpointLayouts() {
      const defaultLayout = getDefaultLayout();
      return {
        xl: self.xl?.gridLayout || defaultLayout,
        lg: self.lg?.gridLayout || defaultLayout,
        md: self.md?.gridLayout || defaultLayout,
        sm: defaultLayout,
        xs: defaultLayout
      };
    }
  };
};
