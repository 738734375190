import React, { useCallback } from 'react';
import { getIdentifier } from '~/mst/utils';
import Yup from '~/utils/yup';
import { Form as FinalForm } from 'react-final-form';
import get from 'lodash/get';

import Modal from '~/components/modal';
import { useValidationSchema } from '~/hooks/use_validate';
import useLocales from '~/hooks/use_locales';
import type { IAdamSensor } from '~/mst/models/device/sensor/adam';
import toasts from '~/utils/toasts';
import { captureException } from '~/services/sentry';
import ConfigureMeasurementForm from './form';

type ConfigureMeasurementModalProps = {
  open: boolean;
  channelId: string;
  onClose: () => void;
  sensor: IAdamSensor;
};

function ConfigureMeasurementModal({ open, onClose, sensor, channelId }: ConfigureMeasurementModalProps) {
  const { t } = useLocales();

  const initialValues = {};
  const AddEditNodeSchema = Yup.object().shape({
    name: Yup.string().required(),
    min: Yup.number().required(),
    max: Yup.number().required(),
    unit: Yup.string().nullable()
  });

  const validate = useValidationSchema(AddEditNodeSchema);

  const handleSave = useCallback(
    async (values) => {
      try {
        const { name, min, max, unit } = values;
        const [range1, range2] = sensor.getRangeByChannelId(channelId);
        const dataPoint = sensor.getDataPointByChannelId(channelId);
        const payload = {
          conditions: {
            scale: [getIdentifier(dataPoint), +range1, +range2, +min, +max]
          },
          unit,
          name
        };
        await sensor?.node?.data_points.createMetric(payload);
        onClose();
        toasts.success(t('notifications.success.add_stat_message'));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [sensor, onClose, t, channelId]
  );

  return (
    <Modal
      open={open}
      handleClose={onClose}
      title={t('sensor.data_point.add_scale_title', {
        channel_name: t('device_monitor.channel', { id: channelId }),
        conf: get(sensor.channels, channelId)
      })}
    >
      <FinalForm onSubmit={handleSave} initialValues={initialValues} validate={validate}>
        {({ handleSubmit }) => <ConfigureMeasurementForm handleSubmit={handleSubmit} handleClose={onClose} sensor={sensor} />}
      </FinalForm>
    </Modal>
  );
}

export default ConfigureMeasurementModal;
