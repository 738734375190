/* eslint-disable @typescript-eslint/no-use-before-define */
import { flow, getParent } from 'mobx-state-tree';
import { getIdentifier } from '~/mst/utils';
import api from '~/api';
import type { IDataPointsNode } from '~/mst/models/data_points/node';
import type { IDataPointMetricModel } from './model';

export default (self: IDataPointMetricModel) => ({
  destroy: flow(function* destroy() {
    self.startSyncing();
    try {
      yield api.nodes.destroyMetric(self.node_id, getIdentifier(self)!);
      const models = getParent<IDataPointsNode>(self, 2);
      models.remove(self);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});
