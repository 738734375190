import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { ModelV2 as Model } from '~/mst/models/abstract/model';
import MaybeString from '~/mst/models/abstract/maybe_string';

const AssistantModel = t
  .compose(
    t.model({
      name: t.maybeNull(MaybeString),
      metadata: t.frozen()
    }),
    Model
  )
  .named('Assistant');

export interface IAssistantModel extends Instance<typeof AssistantModel> {}
export interface IAssistantModelSnapshotOut extends SnapshotOut<typeof AssistantModel> {}

export default AssistantModel;
