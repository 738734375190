import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { ModelV2 as Model } from '~/mst/models/abstract/model';
import MaybeString from '~/mst/models/abstract/maybe_string';

const ThreadModel = t
  .compose(
    t.model({
      name: t.maybeNull(MaybeString)
    }),
    Model
  )
  .named('Thread');

export interface IThreadModel extends Instance<typeof ThreadModel> {}
export interface IThreadModelSnapshotOut extends SnapshotOut<typeof ThreadModel> {}

export default ThreadModel;
