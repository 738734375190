import { Grid, Stack } from '@mui/material';
import useMst from '~/hooks/use_mst';

import Filters from '~/components/filters';

function FilterBar() {
  const { users } = useMst();

  return (
    <Grid container spacing={2} sx={{ py: 2, px: 3 }}>
      <Grid item xs={12} sm={4} lg={6} md={6}>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <Filters collection={users} />
        </Stack>
      </Grid>
    </Grid>
  );
}

export default FilterBar;
