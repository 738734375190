import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import get from 'lodash/get';

import useNavigate from '~/hooks/use_navigate';
import useMst from '~/hooks/use_mst';
import { persistedStore } from '~/mst/store';
import useFetch from '~/hooks/use_fetch';
import LoadingBox from '~/components/loading_box';
import Container from '~/components/container';

function Initial() {
  const store = useMst();
  const { auth } = useMst();
  const { navigate } = useNavigate();
  const { organizations } = store;

  const { isFetched, isFetching } = useFetch(organizations, null, { excludeParams: ['organization_id'] });

  useEffect(() =>
    autorun(() => {
      if (isFetched && persistedStore.isRehydrated) {
        if (organizations.isEmpty) {
          navigate('all/home');
        }
        if (organizations.valueLabelPairsIsMemberWithAll.find(({ value }) => value === auth.organization_id)) {
          navigate(`${auth.organization_id}/home`);
        } else {
          navigate(`${get(organizations.valueLabelPairsIsMemberWithAll, '[0].value', 'all')}/home`);
        }
      }
    })
  );

  return <Container>{isFetching && <LoadingBox />}</Container>;
}

export default observer(Initial);
