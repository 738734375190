/* eslint-disable @typescript-eslint/naming-convention */
import { IThreadsModel } from './model';

export default (self: IThreadsModel) => ({
  get blue() {
    return self.toArray.find(({ isBlue }) => isBlue);
  },
  get aqms() {
    return self.toArray.find(({ isAqms }) => isAqms);
  }
});
