import React from 'react';
import { Card, CardContent } from '@mui/material';

function ReportContent() {
  return (
    <Card>
      <CardContent>NoesisGPT</CardContent>
    </Card>
  );
}

export default React.memo(ReportContent);
