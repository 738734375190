/* eslint-disable no-prototype-builtins */
import { useCallback, useState } from 'react';
import { Box, Chip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { getIdentifier } from '~/mst/utils';
import SettingsIcon from '@mui/icons-material/Settings';
import { ISensor } from '~/mst/models/device/sensor';
import { BodyModule } from '~/pages/device_edit/components';
import useLocales from '~/hooks/use_locales';
import useModal from '~/hooks/use_modal';

import { SensorProperty } from '../components';
import { MeasurementsContainer } from '../styled';
import ConfigureMeasurementModal from './configure_measurement_modal';

type SensorType = {
  sensor: ISensor;
};

function Adam({ sensor }: SensorType) {
  const { t } = useLocales();
  const { open, handleClose, handleOpen } = useModal();
  const [measurementId, setMeasurementsId] = useState(null);

  const handleClick = useCallback(
    (e) => {
      setMeasurementsId(e.currentTarget.dataset.measurementid);
      handleOpen();
    },
    [setMeasurementsId, handleOpen]
  );

  return (
    <BodyModule title={t('device_monitor.forms.settings')} columns={1} expandable expanded>
      <Box>
        <SensorProperty name="optional_measurements">
          <MeasurementsContainer component="ul">
            {sensor.rawDataPoints?.map((dp) => (
              <Chip
                key={getIdentifier(dp)}
                variant="outlined"
                icon={<SettingsIcon fontSize="12px" />}
                data-measurementid={getIdentifier(dp)}
                label={dp.name}
                onClick={handleClick}
              />
            ))}
          </MeasurementsContainer>
        </SensorProperty>
      </Box>
      <ConfigureMeasurementModal
        open={open}
        onClose={handleClose}
        sensor={sensor}
        dataPoint={sensor?.rawDataPoints?.find(({ _id: id }) => id === measurementId)}
      />
    </BodyModule>
  );
}

export default observer(Adam);
