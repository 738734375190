import React, { useEffect, useMemo } from 'react';
import { CardContent, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useFormState, useForm } from 'react-final-form';
import { observable, action, computed } from 'mobx';
import { OnChange } from 'react-final-form-listeners';
import isString from 'lodash/isString';
import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import first from 'lodash/first';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import useLocales from '~/hooks/use_locales';
import { filterByUserSettings } from '~/mst/models/data_points/node/views';
import DataPoints from '~/mst/models/data_points/node';
import HiddenInput from '~/components/final_form/hidden_input';
import TextArea from '~/components/final_form/text_area';
import FormGrid from '~/components/@material-extend/form_grid';
import useFetch from '~/hooks/use_fetch';
import Select from '~/components/final_form/select';
import DevicesSelect from '~/components/devices_select';
import DatePicker from '~/components/final_form/date_picker';
import useMst from '~/hooks/use_mst';
import { PATHS } from '~/utils/constants';

const paramsOragnizationId = observable(
  {
    organization_id: null,
    setOrganizationId(value) {
      this.organization_id = value;
    }
  },
  {
    setOrganizationId: action
  }
);
const paramsNodeIds = observable(
  {
    node_id: null,
    setNodeId(value) {
      this.node_id = value;
    }
  },
  {
    setNodeId: action
  }
);

function EditReportForm() {
  const { t } = useLocales();
  const { nodes, auth } = useMst();
  const {
    values: { organization_id: organizationId, from, time_zone: timeZone, node_ids: nodeIds }
  } = useFormState({ subscription: { values: true } });

  const dataPoints = useMemo(() => DataPoints.create({}), []);
  useEffect(() => paramsNodeIds.setNodeId(getOptionsForPayload(nodeIds)), [nodeIds]);

  const { change } = useForm();
  useEffect(() => paramsOragnizationId.setOrganizationId(organizationId), [organizationId]);
  useFetch(dataPoints, paramsNodeIds);

  const tempUnits = auth?.user?.temp_units;
  const dataPointsValueLabelPairs = useMemo(
    () => computed(() => filterByUserSettings(dataPoints.defaultValueLabelPairs, tempUnits)),
    [dataPoints.defaultValueLabelPairs, tempUnits]
  ).get();
  const { isFetching } = useFetch(nodes, paramsOragnizationId);
  const parameterOptions = useMemo(
    () =>
      computed(() => {
        return flow(
          compact,
          groupBy(({ label }) => label),
          map.convert({ cap: false })((values, name) => ({ models: values.map((value) => value.model), label: name, value: name })),
          sortBy('label')
        )(dataPointsValueLabelPairs);
      }),
    [dataPointsValueLabelPairs]
  ).get();

  return (
    <Box>
      <CardContent sx={{ padding: 0, paddingBottom: '0 !important' }}>
        <FormGrid rowGap={3}>
          <HiddenInput name="time_zone" />
          <DevicesSelect
            allowSelectAll
            name="node_ids"
            loading={isFetching}
            label={t('attributes.report.node_ids')}
            options={nodes.monitorableValueLabelPairsSortedByStatus || []}
            multiple
          />
          <Select
            searchable
            multiple
            name="parameters"
            label={t('thiamis.download_csv.parameters')}
            options={parameterOptions}
            loading={dataPoints.isFetching}
          />
          <FormGrid columns="2" rowGap={1}>
            <DatePicker name="from" label={t('thiamis.download_csv.from')} timezone={timeZone} disableFuture />
            <DatePicker
              name="to"
              label={t('thiamis.download_csv.to')}
              timezone={timeZone}
              minDate={isString(from) && DateTime.fromISO(from)?.isValid ? DateTime.fromISO(from) : null}
              disableFuture
            />
          </FormGrid>
          <Box
            sx={{
              textarea: {
                padding: '16px',
                width: '100%',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '16px',
                color: '#333',
                lineHeight: '24px',
                borderColor: '#dfdfdf',
                borderRadius: '8px'
              }
            }}
          >
            <TextArea name="prompt" minRows={4} label="Prompt" />
          </Box>
        </FormGrid>
      </CardContent>
      <OnChange name="node_ids">
        {async (value) => {
          const nodesIds = getOptionsForPayload(value);
          if (!isEmpty(nodesIds)) {
            await nodes.fetchDataPoints({ last: 1, path: [PATHS.TIMEZONE], node_id: nodesIds });
            const timezone = flow(
              map((id) => nodes.getById(id)?.data_points?.getByPath(PATHS.TIMEZONE)?.lastValue),
              compact,
              uniq,
              first
            )(nodesIds);
            if (timezone) {
              change('time_zone', timezone);
            }
          }
        }}
      </OnChange>
    </Box>
  );
}

export default observer(EditReportForm);
