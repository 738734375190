/* eslint-disable @typescript-eslint/naming-convention */
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import includes from 'lodash/includes';
import { getStore, getIdentifier } from '~/mst/utils';
import { IWidget } from '~/mst/models/widget';
import type { IDashboardModel } from './model';

export default (self: IDashboardModel) => {
  return {
    get owner() {
      return getStore(self).organizations.getById(self.organization_id);
    },
    get creator() {
      return getStore(self).users.getById(self.user_id);
    },
    get nodeIds() {
      return flow(
        map((widget: IWidget) => {
          const nodeIds = widget.node_id;
          if (includes(nodeIds, 'all')) {
            return widget.nodes?.toArray.map((node) => getIdentifier(node));
          }
          return nodeIds;
        }),
        flatten,
        uniq
      )(self.widgets.toArray);
    },
    get canBeShared() {
      return !!self.user_id;
    },
    get isShared() {
      return !self.user_id;
    }
  };
};
