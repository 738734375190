import { Card, Stack, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import useLocales from '~/hooks/use_locales';
import DeviceSignalContainer from '~/components/device_signal';
import useMst from '~/hooks/use_mst';
import DevicePower from '~/components/device_power';
import humanizer from '~/utils/humanize_duration';

import type { INode } from '~/mst/models/node';
import LoadingBox from '~/components/loading_box';
import MCardHeader from '~/components/@material-extend/MCardHeader';
import { HeaderDeviceWrapperStyled, ItemValueStyled, HeaderModuleStyled } from '~/pages/device_edit/components/styled';
import { ItemTitle, HeaderModule } from '~/pages/device_edit/components';

type DetailsBlockType = {
  node: INode;
};

function DetailsBlock({ node }: DetailsBlockType) {
  const { t } = useLocales();
  const { organizations, nodes } = useMst();

  if (node?.isFetching) {
    return <LoadingBox />;
  }

  return (
    <Card>
      <HeaderDeviceWrapperStyled>
        <MCardHeader title="Details" />
      </HeaderDeviceWrapperStyled>
      {node?.isFetching || nodes?.isFetching ? (
        <LoadingBox />
      ) : (
        <HeaderModule node={node} icon={node?.thiamis?.name} iconMaxWidth="150px" sx={{ borderBottom: 0 }}>
          <HeaderModuleStyled>
            <Grid container spacing={1} direction="row">
              <Grid item xs={6} sm={6} md={6}>
                <ItemTitle>{t('device_monitor.forms.model')}</ItemTitle>
                <ItemValueStyled>{node?.thiamis?.modelName}</ItemValueStyled>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <ItemTitle>{t('device_monitor.forms.serial_number')}</ItemTitle>
                <ItemValueStyled>{node?.serial}</ItemValueStyled>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <ItemTitle>{t('attributes.thiamis.battery')}</ItemTitle>
                <DevicePower
                  iconLeft
                  batteryLevel={node?.batteryLevel}
                  batteryStatus={node?.batteryStatus}
                  batteryCharge={node?.batteryCharge}
                  isAirthinx={node?.isAirthinx}
                />
              </Grid>
              {organizations.has(node?.organization_id) && (
                <Grid item xs={6} sm={6} md={6}>
                  <ItemTitle>{t('device_monitor.forms.owner')}</ItemTitle>
                  <ItemValueStyled>{organizations.getById(node?.organization_id)?.name}</ItemValueStyled>
                </Grid>
              )}
              <Grid item xs={6} sm={6} md={6}>
                <ItemTitle>{t('attributes.thiamis.gsm_network')}</ItemTitle>
                <DeviceSignalContainer
                  iconLeft
                  networkSignal={node?.networkSignal}
                  hasWifi={node?.hasWifi}
                  networkName={node?.networkName}
                  networkSignalUnit={node?.networkSignalUnit}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <ItemTitle>{t('device_monitor.forms.reporting_interval')}</ItemTitle>
                <ItemValueStyled>
                  {humanizer.humanizeDuration(node?.interval, {
                    smallestUnit: 'min',
                    units: ['m'],
                    spacer: ' ',
                    languages: {
                      shortEn: {
                        m: () => 'min'
                      }
                    }
                  })}
                </ItemValueStyled>
              </Grid>
              {node?.firmware && (
                <Grid item xs={6} sm={6} md={6}>
                  <ItemTitle>{t('sensor.configuration.firmware')}</ItemTitle>
                  <ItemValueStyled>{node?.firmware}</ItemValueStyled>
                </Grid>
              )}
              {Boolean(node?.shared_to?.filter((id) => Boolean(organizations.getById(id)?.name)).length) && (
                <Grid item xs={6} sm={6} md={6}>
                  <ItemTitle>{t('device_monitor.forms.shared_to')}</ItemTitle>
                  <Stack>
                    {node?.shared_to.map((id) => (
                      <ItemValueStyled key={id} title={organizations.getById(id)?.name}>
                        {organizations.getById(id)?.name}
                      </ItemValueStyled>
                    ))}
                  </Stack>
                </Grid>
              )}
            </Grid>
          </HeaderModuleStyled>
        </HeaderModule>
      )}
    </Card>
  );
}

export default observer(DetailsBlock);
