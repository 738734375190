import React, { useCallback, useState } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { PERMISSIONS } from '~/mst/models/permissions/types';
import useLocales from '~/hooks/use_locales';
import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import ConfirmDialog from '~/components/confirm_dialog';
import useMst from '~/hooks/use_mst';

const ICON = {
  mr: 2,
  width: 20,
  height: 20
};

function ActionsFormatter({ row }: { row: any }) {
  const { ActionsMenu, handleClose, handleOpen } = useActionsPopover();
  const { t } = useLocales();
  const { roles, id, email } = row;
  const { users, organizations } = useMst();
  const { auth } = useMst();
  const { organizationId, role } = roles[0] || {};
  const organization = organizations.getById(organizationId);
  const user = users.getById(id);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = useCallback(
    async (event) => {
      event.stopPropagation();
      try {
        await user.deletePermission(organization?.external_id);
        await users.fetch({ organization_id: organizationId });
        setIsDeleting(false);
      } catch (e) {}
    },
    [user, organization?.external_id, users, organizationId]
  );

  const handleStartDelete = useCallback(
    (event) => {
      event.stopPropagation();
      setIsDeleting(true);
      handleClose();
    },
    [handleClose]
  );

  const handleCancelDelete = useCallback((event) => {
    event.stopPropagation();
    setIsDeleting(false);
  }, []);

  const canDelete = auth.user.isSupport || auth.user.hasPermission(PERMISSIONS.ManageUsers, organizationId);

  if (canDelete) {
    return (
      <>
        <IconButton onClick={handleOpen}>
          <MoreVertIcon />
        </IconButton>
        <ActionsMenu>
          <MenuItem onClick={handleStartDelete} sx={{ color: 'error.main' }}>
            <DeleteIcon sx={ICON} />
            {t('users.remove_role')}
          </MenuItem>
        </ActionsMenu>
        <ConfirmDialog
          open={isDeleting}
          onClose={handleCancelDelete}
          onConfirm={handleDelete}
          title={t('users.remove_role')}
          content={t('users.remove_user_role_confirmation.text', { role: t(`users.roles.${role}`), name: organization?.name, email })}
          confirmLabel={t('base.buttons.delete')}
          confirmButtonType="error"
        />
      </>
    );
  }
}

export default ActionsFormatter;
