import React, { useCallback, useMemo, useEffect } from 'react';
import { Chip } from '@mui/material';
import { useForm, useFormState } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { OnChange } from 'react-final-form-listeners';
import { observable, action, computed } from 'mobx';

import I18n from '~/utils/i18n';
import useFetch from '~/hooks/use_fetch';
import useMst from '~/hooks/use_mst';
import AddEditConfigurationModal from '~/components/widgets/common/configuration_form';
import Autocomplete from '~/components/final_form/select';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import DevicesSelect from '~/components/devices_select';
import TextInput from '~/components/final_form/text_input';
import FormGrid from '~/components/@material-extend/form_grid';
import DataPoints from '~/mst/models/data_points/node';
import { ListItemStyled, ExternalIdStyled } from '~/components/devices_select/styled';
import type { IDashboard } from '~/mst/models/dashboard';
import { filterByUserSettings } from '~/mst/models/data_points/node/views';
import { GroupHeader } from './styled';

type FormProps = {
  onClose: () => void;
  onSave: () => void;
  isNew?: boolean;
  dashboard: IDashboard;
};

const params = observable(
  {
    node_id: null,
    setNodeId(value) {
      this.node_id = value;
    }
  },
  {
    setNodeId: action
  }
);

function ChartConfigurationForm({ onClose, onSave, isNew, dashboard }: FormProps) {
  const { nodes, auth } = useMst();
  useFetch(nodes, { organization_id: dashboard?.organization_id });

  const { change } = useForm();

  const {
    values: { node_id: nodeIds },
    invalid
  } = useFormState({ subscription: { values: true, invalid: true } });

  useEffect(() => params.setNodeId(getOptionsForPayload(nodeIds).filter((id) => Boolean(nodes.getById(id)))), [nodes, nodeIds]);

  const dataPoints = useMemo(() => DataPoints.create({}), []);
  useFetch(dataPoints, params);

  const tempUnits = auth?.user?.temp_units;
  const dataPointsOptions = useMemo(
    () =>
      computed(() =>
        filterByUserSettings(
          dataPoints.defaultValueLabelPairs.sort(
            // eslint-disable-next-line no-unsafe-optional-chaining
            (a, b) => -nodes.getById(b.model.node_id)?.presentName.localeCompare(nodes.getById(a.model.node_id)?.presentName)
          ),
          tempUnits
        )
      ),
    [dataPoints, tempUnits, nodes]
  ).get();

  const nodesOptions = nodes.monitorableValueLabelPairs || [];

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSave = useCallback(() => {
    onSave();
    handleClose();
  }, [onSave, handleClose]);

  return (
    <AddEditConfigurationModal isNew={isNew} onClose={handleClose} onSave={handleSave} disabled={invalid}>
      <FormGrid>
        <TextInput label="Name" name="name" />
        <DevicesSelect allowSelectAll name="node_id" label={I18n.t('models.node')} options={nodesOptions} multiple loading={nodes.isFetching} />
        <Autocomplete
          searchable
          name="selected_data_point_ids"
          label={I18n.t('models.parameter')}
          options={dataPointsOptions}
          multiple
          loading={dataPoints.isFetching}
          groupBy={({ model }) => model?.node_id}
          disableCloseOnSelect
          handleHomeEndKeys={false}
          renderGroup={(groups) => {
            const node = nodes.getById(groups.group);
            return (
              <li key={groups.key}>
                <GroupHeader>
                  <ListItemStyled>
                    {node?.presentName}
                    <ExternalIdStyled className="ExternalId">{node?.serial}</ExternalIdStyled>
                  </ListItemStyled>
                </GroupHeader>
                <ul>{groups.children}</ul>
              </li>
            );
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue
              .filter((option) => Boolean(option))
              .map((option, index) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Chip {...getTagProps({ index })} label={`${option?.label} (${nodes.getById(option?.model?.node_id)?.presentName})`} />
              ))
          }
        />
      </FormGrid>
      <OnChange name="node_id">
        {() => {
          dataPoints.reset();
          change('selected_data_point_ids', []);
          return null;
        }}
      </OnChange>
    </AddEditConfigurationModal>
  );
}

export default observer(ChartConfigurationForm);
