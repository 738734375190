/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import { getIdentifier } from '~/mst/utils';
import api from '~/api';
import type { IOrganizationModel } from './model';

export default (self: IOrganizationModel) => ({
  update: flow(function* update(payload) {
    self.startSyncing();
    try {
      const { data } = yield api.organizations.update(getIdentifier(self), payload);
      applySnapshot(self, data);
      self.finishSyncing();
      return data;
    } catch (error) {
      self.failSyncing(error);
    }
    return null;
  }),
  lock: flow(function* update() {
    self.startSyncing();
    try {
      const { data } = yield api.organizations.lock(self.external_id);
      applySnapshot(self, data);
      self.finishSyncing();
      return data;
    } catch (error) {
      self.failSyncing(error);
    }
    return null;
  }),
  unlock: flow(function* update() {
    self.startSyncing();
    try {
      const { data } = yield api.organizations.unlock(self.external_id);
      applySnapshot(self, data);
      self.finishSyncing();
      return data;
    } catch (error) {
      self.failSyncing(error);
    }
    return null;
  })
});
