/* eslint-disable @typescript-eslint/naming-convention */
import { flow, getParent, applySnapshot } from 'mobx-state-tree';
import { getIdentifier } from '~/mst/utils';
import omit from 'lodash/omit';
import type { INode } from '~/mst/models/node';
import api from '~/api';
import { IDeviceModel } from './model';
import { preProcessor } from './snapshot_processors';

export default (self: IDeviceModel) => ({
  update: flow(function* update(values) {
    try {
      self.startSyncing();
      const node = getParent<INode>(self, 3);
      const payload = { devices: [...node.devices.toJSON()] };
      const index = payload.devices.findIndex(({ _id: id }) => id === getIdentifier(self));
      payload.devices[index] = omit(
        {
          ...payload.devices[index],
          ...values
        },
        ['locationType', 'hasWiFi', 'configuration', 'extra_configuration']
      );
      const { data } = yield api.nodes.update(getIdentifier(node), payload);
      applySnapshot(self, preProcessor(data.devices.find(({ _id: id }) => id === getIdentifier(self))));
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});
