/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';
import Syncable from '~/mst/models/abstract/syncable';
import CollectionMap from '~/mst/models/abstract/collection_map';
import Thread from '../thread';

const ThreadsModel = t.compose(CollectionMap(Thread), Syncable, Fetchable({ cache: true }));

export interface IThreadsModel extends Instance<typeof ThreadsModel> {}

export default ThreadsModel;
