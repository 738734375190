import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { ModelV2 as Model } from '~/mst/models/abstract/model';
import Syncable from '~/mst/models/abstract/syncable';
import Messages from './messages';

const ChatModel = t
  .compose(
    t.model({
      name: t.maybeNull(t.string),
      messages: t.optional(Messages, []),
      assistant_id: t.maybeNull(t.string),
      thread_id: t.maybeNull(t.string)
    }),
    Syncable,
    Model
  )
  .volatile(() => ({
    progress: '',
    text_delta: '',
    thinking: false
  }))
  .named('Chat');

export interface IChatModel extends Instance<typeof ChatModel> {}
export interface IChatModelSnapshotOut extends SnapshotOut<typeof ChatModel> {}

export default ChatModel;
