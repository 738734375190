import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Grid, Stack } from '@mui/material';
import useLocales from '~/hooks/use_locales';
import Filters from '~/components/filters';
import useMst from '~/hooks/use_mst';
import Modal from '~/components/modal';
import ButtonAdaptive from '~/components/adaptive_button';
import { PERMISSIONS } from '~/mst/models/permissions/types';

import AddPartnerForm from './add_partner_form';

function FormTop() {
  const { t } = useLocales();
  const { partnerships } = useMst();
  const { auth } = useMst();
  const [isModalCreatePartnerOpen, setIsModalCreatePartnerOpen] = useState(false);

  return (
    <Grid container spacing={2} sx={{ py: 2, px: 3 }}>
      <Grid item xs={12} sm={4} lg={6} md={6}>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <Filters collection={partnerships} />
        </Stack>
      </Grid>
      {auth.user?.hasPermission(PERMISSIONS.ManageOrganizations) && (
        <Grid item xs={12} sm={8} lg={6} md={6} sx={{ textAlign: 'right' }}>
          <ButtonAdaptive
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
            $mobileFullWidth
            breakpoint="md"
            onClick={() => setIsModalCreatePartnerOpen(true)}
          >
            {t('partnerships.create_new')}
          </ButtonAdaptive>
        </Grid>
      )}
      <Modal open={isModalCreatePartnerOpen} handleClose={() => setIsModalCreatePartnerOpen(false)} title={t('partnerships.create_new')}>
        <AddPartnerForm />
      </Modal>
    </Grid>
  );
}

export default FormTop;
