/* eslint-disable react/require-default-props */
import { SxProps } from '@mui/material';
import BatteryContainer from '~/components/device_power/battery_level';
import BatteryLevelWithStatus from '~/components/device_power/battery_level_with_status';
import UsbContainer from '~/components/device_power/usb_source';

import useLocales from '~/hooks/use_locales';

type ConnectionType = {
  batteryLevel?: number;
  batteryStatus?: string;
  batteryCharge?: number;
  iconLeft?: boolean;
  showLabel?: boolean;
  isAirthinx?: boolean;
  sx?: SxProps;
};

function DeviceConnection({ batteryLevel, batteryCharge, batteryStatus, iconLeft = false, sx, showLabel = true, isAirthinx }: ConnectionType) {
  const { t } = useLocales();
  if (batteryCharge != null && batteryStatus != null) {
    return (
      <BatteryLevelWithStatus
        iconLeft={iconLeft}
        value={batteryCharge}
        status={batteryStatus}
        title={t('thiamis.more_info.battery_level', { value: batteryCharge })}
        noValueText={t('base.labels.n_a')}
        unit="%"
        showLabel={showLabel}
        sx={sx}
      />
    );
  }

  if (isAirthinx) {
    return <UsbContainer showLabel={showLabel} iconLeft={iconLeft} />;
  }

  return (
    <BatteryContainer
      iconLeft={iconLeft}
      value={batteryLevel}
      title={t('thiamis.more_info.battery', { value: batteryLevel })}
      noValueText={t('base.labels.n_a')}
      unit="V"
      showLabel={showLabel}
      sx={sx}
    />
  );
}

export default DeviceConnection;
