/* eslint-disable @typescript-eslint/naming-convention */
import { getIdentifier } from '~/mst/utils';
import type { IArchivesModel } from './model';

export default (self: IArchivesModel) => {
  return {
    get filteredList() {
      return self.filteredModels.map((archive) => ({
        id: getIdentifier(archive),
        serial: archive.serial,
        from: archive.from,
        to: archive.to,
        organizationName: archive.organizationName,
        filename: archive.filename
      }));
    }
  };
};
