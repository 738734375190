import React, { useState } from 'react';
import { Grid, Table, TableHeaderRow, TableGroupRow, TableColumnVisibility } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, GroupingState, CustomGrouping } from '@devexpress/dx-react-grid';
import { observer } from 'mobx-react-lite';
import { Grid as MuiGrid, Stack, Box, Typography } from '@mui/material';
import { ListItemStyled, ExternalIdStyled } from '~/components/organizations_select/styled';
import withAutoupdate from '~/hocs/with_autoupdate';
import StatusIcon from '~/components/status_icon';
import { GridStyled, RowStyled } from '~/components/react_grid/styled';
import { Container } from '~/components/react_grid';
import useSorting from '~/hooks/react_grid/use_sorting';
import useLoading from '~/hooks/react_grid/use_loading';
import { INodeWidget } from '~/mst/models/widget/node_widget';
import DevicePower from '~/components/device_power';
import DeviceSignal from '~/components/device_signal';

import { TableContainer } from './components';
import { LastValueProvider, MeasurementsProvider, NameProvider } from './info_providers';

const columns = [
  { name: 'shortPresentName', title: 'Parameter', sortingEnabled: false },
  { name: 'node_id', title: ' ', sortingEnabled: false },
  { name: 'measurements', title: ' ', sortingEnabled: false },
  { name: 'lastValue', title: 'Value' }
];

const StyledRowWithHover = withAutoupdate(
  observer(({ row, ...rest }) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <RowStyled {...rest} row={row} hover px={0} />;
  })
);
const getChildGroups = (groups) => groups.map((group) => ({ key: group.key, childRows: group.items }));

const getRowId = ({ _id }) => _id;

function NodeWidgetContent({ widget, height }: { widget: INodeWidget; height?: number }) {
  const { messages, RootComponent } = useLoading(widget);
  const { sorting, handleSortingChange } = useSorting([]);
  const [grouping] = useState([{ columnName: 'node_id' }]);

  const DeviceTitle = React.memo(
    withAutoupdate(({ row: { key: nodeId } }) => {
      const node = widget.nodes.getById(nodeId);
      return (
        <Stack direction="row" gap={1}>
          <StatusIcon statusType={node?.statusType()} statusText={node?.statusText()} size="small" />
          <MuiGrid container spacing={1} justifyContent="center" alignItems="center">
            <MuiGrid item xs={9}>
              <ListItemStyled>
                <Typography variant="subtitle2">{node.name}</Typography>
                <ExternalIdStyled className="ExternalId">{node?.serial!}</ExternalIdStyled>
              </ListItemStyled>
            </MuiGrid>
            <MuiGrid item xs={3}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Stack direction="row" spacing={1.5}>
                  <DevicePower
                    batteryLevel={node?.batteryLevel}
                    batteryStatus={node?.batteryStatus}
                    batteryCharge={node?.batteryCharge}
                    showLabel={false}
                    isAirthinx={node?.isAirthinx}
                  />
                  <DeviceSignal
                    showLabel={false}
                    iconLeft
                    networkSignal={node?.networkSignal}
                    hasWifi={node?.hasWifi}
                    networkName={node?.networkName}
                    networkSignalUnit={node?.networkSignalUnit}
                  />
                </Stack>
              </Box>
            </MuiGrid>
          </MuiGrid>
        </Stack>
      );
    })
  );

  return (
    <TableContainer sx={{ height: height ? `${height}px` : '100%' }}>
      <GridStyled>
        <Grid rows={widget.data || []} columns={columns} rootComponent={RootComponent} getRowId={getRowId}>
          <SortingState sorting={sorting} onSortingChange={handleSortingChange} />
          <IntegratedSorting />
          <GroupingState grouping={widget.nodeId.length === 1 ? [] : grouping} defaultExpandedGroups={widget.nodeId} />
          <CustomGrouping getChildGroups={getChildGroups} />
          <LastValueProvider />
          <MeasurementsProvider />
          <NameProvider />
          <Table
            messages={messages}
            containerComponent={Container}
            columnExtensions={[
              { columnName: 'shortPresentName' },
              { columnName: 'lastValue', width: 85 },
              { columnName: 'measurements', align: 'center' }
            ]}
            rowComponent={StyledRowWithHover}
          />
          {widget.nodeId.length === 1 && <TableColumnVisibility defaultHiddenColumnNames={['node_id']} />}
          <TableHeaderRow showSortingControls />
          {widget.nodeId.length > 1 && <TableGroupRow rowComponent={StyledRowWithHover} contentComponent={DeviceTitle} indentColumnWidth={24} />}
        </Grid>
      </GridStyled>
    </TableContainer>
  );
}

export default observer(NodeWidgetContent);
