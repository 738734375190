/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Helmet } from 'react-helmet-async';
import useLocales from '~/hooks/use_locales';
import { analyticsPage } from '~/services/segment';
import useNavigate from '~/hooks/use_navigate';
import useMst from '~/hooks/use_mst';

const PagePropTypes = {
  title: PropTypes.string
};

type PageType = InferProps<typeof PagePropTypes>;

function withPageTitle(WrappedComponent: React.ComponentType) {
  return function WrappedWithPageTitle(props: PageType) {
    const { title, ...restProps } = props;
    const {
      auth: { user }
    } = useMst();
    const { t, i18n } = useLocales();
    const { path } = useNavigate();

    const tEn = i18n.getFixedT('en-US');
    useEffect(() => {
      if (user?.id) {
        analyticsPage(tEn(title, { defaultValue: title }), {
          path
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, title, user?.id]);

    return (
      <>
        <Helmet>
          <title>{title && `${t(title)} | Environet Console`}</title>
        </Helmet>
        <WrappedComponent {...restProps} />
      </>
    );
  };
}

withPageTitle.propTypes = PagePropTypes;

export default withPageTitle;
