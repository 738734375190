import { Instance } from 'mobx-state-tree';
import model from './model';
import actions from './actions';
import views from './views';

const Analytics = model.actions(actions).views(views);

export interface IAnalyticsStore extends Instance<typeof Analytics> {}

export default Analytics;
