/* eslint-disable no-param-reassign */
import isEmpty from 'lodash/isEmpty';
import findLastIndex from 'lodash/findLastIndex';
import { Duration, DateTime } from 'luxon';
import compact from 'lodash/compact';
import { DEVICE_STATUSES, PATHS } from '~/utils/constants';
import { getOnlineStatusFromAQ } from '~/mst/models/device/utils';

import { getIdentifier, getStore } from '~/mst/utils';
import I18n from '~/utils/i18n';
import type { INodeModel } from '../model';

export default (self: INodeModel) => {
  function getIsOnline() {
    const now = DateTime.now().toUTC().toMillis();
    const { last_online: lastOnline } = self;
    const status = self.data_points?.getByPath(PATHS.ONLINE)?.lastValue;
    return Boolean(status) || now - lastOnline < self.extraReportingInterval;
  }
  return {
    get presentName() {
      if (isEmpty(self.name)) {
        return self.serial;
      }
      return self.name;
    },
    get defaultDataPoints() {
      return self.data_points?.defaultDataPoints;
    },
    get rawDataPoints() {
      return self.data_points?.rawDataPoints;
    },
    get batteryLevel() {
      return self.data_points?.getByPath(PATHS.BATTERY_VOLTAGE)?.lastValue;
    },
    get batteryStatus() {
      return self.data_points?.getByPath(PATHS.BATTERY_STATUS)?.lastValue;
    },
    get batteryCharge() {
      return self.data_points?.getByPath(PATHS.BATTERY_CHARGE)?.lastValue;
    },
    get hasWifi() {
      const gsmTs = self.data_points?.getByPath(PATHS.GSM_SIGNAL)?.lastTs;
      const wifiTs = self.data_points?.getByPath(PATHS.WIFI_SIGNAL)?.lastTs;

      return wifiTs && wifiTs > gsmTs;
    },
    get gsmSignal() {
      return self.data_points?.getByPath(PATHS.GSM_SIGNAL)?.lastValue;
    },
    get gsmNetwork() {
      return self.data_points?.getByPath(PATHS.GSM_NETWORK)?.lastValue;
    },
    get wifiSignal() {
      return self.data_points?.getByPath(PATHS.WIFI_SIGNAL)?.lastValue;
    },
    get wifiNetwork() {
      return self.data_points?.getByPath(PATHS.WIFI_NETWORK)?.lastValue;
    },
    get wifiNetworks() {
      return self.data_points?.getByPath(PATHS.WIFI_NETWORKS)?.lastValue;
    },
    get timezone() {
      return self.data_points?.getByPath(PATHS.TIMEZONE)?.lastValue;
    },
    get networkName() {
      return self.hasWifi ? self.wifiNetwork : self.gsmNetwork;
    },
    get networkSignal() {
      return self.hasWifi ? self.wifiSignal : self.gsmSignal;
    },
    get networkSignalUnit() {
      return self.data_points?.getByPath(PATHS[self.hasWifi ? 'WIFI_SIGNAL' : 'GSM_SIGNAL'])?.unit;
    },
    get defaultParamValueLabelPairs() {
      return self.data_points?.defaultValueLabelPairs;
    },
    get thiamis() {
      return self.devices?.thiamis;
    },
    get isAirthinx() {
      return self.thiamis?.isAirthinx;
    },
    get isHealthway() {
      return self.thiamis?.isHealthway;
    },
    get extraReportingInterval() {
      if (self.interval < Duration.fromDurationLike({ minutes: 5 }).toMillis()) {
        return self.interval * 3;
      }
      return self.interval + Duration.fromDurationLike({ minutes: 3 }).toMillis();
    },
    get sharedTo() {
      return compact(self.shared_to?.map((id) => getStore(self).organizations.getById(id)));
    },
    get leasedTo() {
      if (self.leased_to) {
        return [getStore(self).organizations.getById(self.leased_to)];
      }
      return [];
    },
    get isOnline() {
      return getIsOnline();
    },
    statusType(value?: number) {
      if (self.status === DEVICE_STATUSES.ACTIVATED) {
        if (getIsOnline()) {
          if (self.thiamis?.hasProfile) {
            return self.data_points?.getByPath(PATHS.AQ)?.statusType(value) || DEVICE_STATUSES.ONLINE;
          }
          return DEVICE_STATUSES.ONLINE;
        }
        return DEVICE_STATUSES.OFFLINE;
      }
      return self.status || DEVICE_STATUSES.OFFLINE;
    },
    statusText(options: { showAQStatus?: boolean } = {}) {
      const { showAQStatus = true } = options;
      const statusType = self.statusType();
      return I18n.t(`thiamis.states.${showAQStatus ? statusType : getOnlineStatusFromAQ(statusType)}`);
    },
    getDataPointById(dataPointId: string) {
      return self.data_points?.toArray.find((dp) => getIdentifier(dp) === dataPointId);
    },
    getDataPointByPath(path: string) {
      return self.data_points?.getByPath(path);
    },
    getDataPointByIds(ids: string[]) {
      return self.data_points?.toArray.filter((dp) => ids.includes(getIdentifier(dp)));
    },
    getTimeOnline(fromTs, toTs) {
      const status = self?.data_points?.getByPath(PATHS.ONLINE);
      if (status?.measurements?.length === 1) {
        const online = status?.measurements[0][1];
        const ts = status?.measurements[0][0];
        if (online && ts <= fromTs) {
          return toTs - fromTs;
        }
      } else {
        const fromTsIndex = findLastIndex(status?.measurements, ([ts]) => ts <= fromTs);
        const toTsIndex = findLastIndex(status?.measurements, ([ts]) => ts >= toTs);
        const measurements = status?.measurements?.slice(fromTsIndex === -1 ? 0 : fromTsIndex, toTsIndex === -1 ? undefined : toTsIndex);
        return measurements?.reduce((acc, [ts, isOnline], index) => {
          if (!isOnline) {
            return acc;
          }
          if (index === 0) {
            acc += Math.abs(fromTs - ts);
          } else if (index === measurements.length - 1) {
            acc += Math.abs(toTs - ts);
          } else acc += measurements[index + 1][0] - ts;
          return acc;
        }, 0);
      }
      return 0;
    },
    getPercentageOnline(fromTs, toTs) {
      const timeOnline = self.getTimeOnline(fromTs, toTs);
      return (timeOnline / (toTs - fromTs)) * 100;
    },
    get iccid() {
      return self.data_points?.getByPath(PATHS.ICCID)?.lastValue;
    },
    get firmware() {
      return self.data_points?.getByPath(PATHS.FIRMWARE)?.lastValue;
    },
    get imei() {
      return self.data_points?.getByPath(PATHS.IMEI)?.lastValue;
    },
    get macAddress() {
      return self.data_points?.getByPath(PATHS.MAC_ADDRESS)?.lastValue;
    },
    get lastTs() {
      return self.thiamis?.lastTs;
    },
    get hasCalibration() {
      return Boolean(self.calibrations?.timestamp);
    },
    get calibrationDate() {
      if (self.hasCalibration) {
        return DateTime.fromMillis(self.calibrations.timestamp).toFormat('LL/dd/yy');
      }
      return null;
    }
  };
};
