import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { computed } from 'mobx';
import get from 'lodash/get';
import { useFormState } from 'react-final-form';

import Autocomplete from '~/components/final_form/select';
import useLocales from '~/hooks/use_locales';
import type { IAlert } from '~/mst/models/alert';
import useMst from '~/hooks/use_mst';
import { filterByUserSettings } from '~/mst/models/data_points/node/views';

type ConditionFieldType = {
  name: string;
  condition: string;
  model: IAlert;
};

function ParameterField(props: ConditionFieldType) {
  const { name, condition, model } = props;
  const { t } = useLocales();
  const { auth } = useMst();
  const { values } = useFormState({ subscription: { values: true } });

  const { deviceId } = get(values, condition);
  const value = get(deviceId, 'value', deviceId);
  const tempUnits = auth?.user?.temp_units;
  const options = useMemo(
    () => computed(() => filterByUserSettings(model?.parametersValueLabelPairs(value), tempUnits)),
    [value, model, tempUnits]
  ).get();

  return (
    <Autocomplete
      searchable
      name={`${condition}.${name}`}
      data-testid={`${condition}.${name}Select`}
      loading={model.isFetching}
      options={options || []}
      label={t('attributes.alert_rule.name')}
      noHelperText
    />
  );
}

export default observer(ParameterField);
