/* eslint-disable @typescript-eslint/naming-convention */
import { getIdentifier } from '~/mst/utils';
import type { IReportsStore } from '~/mst/models/reports';
import type { IReport } from '~/mst/models/report';

export default (self: IReportsStore) => {
  return {
    get filteredList() {
      return self.filteredModels.map((report: IReport) => ({
        id: getIdentifier(report),
        name: report.name,
        type: report.configuration.type,
        from: report.configuration.from,
        to: report.configuration.to,
        description: report.description,
        created_at: report.created_at
      }));
    }
  };
};
