import { getIdentifier } from '~/mst/utils';
import first from 'lodash/first';

export default (self) => ({
  get size() {
    return self.toArray.length;
  },
  get first() {
    return first(self.toArray);
  },
  get isEmpty() {
    return self.size === 0;
  },
  has(id) {
    return Boolean(self.toArray.find((model) => getIdentifier(model) === id));
  },
  getById(id) {
    return self.toArray.find((model) => getIdentifier(model) === id);
  },
  findWhere(attrs: any) {
    return self.toArray.find((model) => Object.entries(attrs).every(([key, value]) => model[key] === value));
  },
  get valueLabelPairs() {
    return self.toArray.map((model: any) => model.valueLabelPair).sort(({ label: label1 }, { label: label2 }) => label1.localeCompare(label2));
  }
});
