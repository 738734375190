import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import { Field as FinalField } from 'react-final-form';

import { getIdentifier } from '~/mst/utils';

import type { IAlert } from '~/mst/models/alert';
import { FUNCTIONS } from '~/mst/models/condition/types';
import NavLink from '~/components/nav_link';
import TimePeriodSelect from '../time_period_select';
import { DeviceNameStyled, DeviceSerialStyled } from './styled';

type ConditionFieldType = {
  name: string;
  condition: string;
  model: IAlert;
};

function ParameterField(props: ConditionFieldType) {
  const { name, condition, model } = props;
  return (
    <FinalField name={`${condition}.${name}`}>
      {({ input }) => {
        const device = model.nodesValueLabelPairsWithFunctions.find(({ value }) => input.value === value);
        if (FUNCTIONS.includes(input.value)) {
          return (
            <Box>
              <i>
                {device?.label}
                {['avg', 'min', 'max'].includes(input.value) && <TimePeriodSelect name={`${condition}.dataPointId.value`} />}
              </i>
            </Box>
          );
        }
        return (
          <Box>
            <DeviceNameStyled>
              {device?.model && <NavLink to={`devices/${getIdentifier(device?.model)}/monitor`}>{device?.label}</NavLink>}
            </DeviceNameStyled>
            <DeviceSerialStyled>{device?.model?.serial}</DeviceSerialStyled>
          </Box>
        );
      }}
    </FinalField>
  );
}

export default observer(ParameterField);
