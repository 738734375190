import { Typography, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { MuiMarkdown } from 'mui-markdown';
import type { IMessage } from '~/mst/models/chat/message';

function Message({ message }: { message: IMessage }) {
  const { text, role } = message;

  return (
    <Stack direction="row" justifyContent={role === 'user' ? 'flex-end' : 'flex-start'}>
      <Typography
        sx={{
          margin: '8px 0',
          padding: '16px',
          borderRadius: '8px',
          backgroundColor: role === 'user' ? '#e0f7fa' : '#fff',
          display: 'inline-block'
        }}
      >
        <MuiMarkdown>{text}</MuiMarkdown>
      </Typography>
    </Stack>
  );
}

export default observer(Message);
