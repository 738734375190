import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Icon } from '@iconify/react';
import personFill from '@iconify/icons-eva/person-fill';
import { Button, Typography } from '@mui/material';

import Link from '~/components/link';
import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import ThemeMenuItem from './theme_menu_item';

import {
  AvatarStyled,
  DividerStyled,
  FooterWrapperStyled,
  IconButtonAnimateStyled,
  IconWrapperStyled,
  InfoWrapperStyled,
  MenuItemStyled,
  MenuPopoverStyled
} from './styled';

function AccountPopover() {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { t } = useLocales();
  const { auth } = useMst();

  const { email, fullName, initials, picture } = auth.user || {};

  const handleLogout = useCallback(() => {
    auth.logout();
  }, [auth]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimateStyled onClick={handleOpen} $open={open}>
        {picture ? <AvatarStyled src={picture} /> : <AvatarStyled>{initials}</AvatarStyled>}
      </IconButtonAnimateStyled>

      <MenuPopoverStyled open={Boolean(open)} anchorEl={open} onClose={handleClose}>
        <InfoWrapperStyled>
          <Typography variant="subtitle1" noWrap>
            {fullName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </InfoWrapperStyled>

        <DividerStyled />

        <MenuItemStyled to="/profile" component={Link} onClick={handleClose}>
          <IconWrapperStyled component={Icon} icon={personFill} />
          Profile
        </MenuItemStyled>
        <ThemeMenuItem />

        <FooterWrapperStyled>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            {t('header.logout')}
          </Button>
        </FooterWrapperStyled>
      </MenuPopoverStyled>
    </>
  );
}

export default observer(AccountPopover);
