import React from 'react';
import isString from 'lodash/isString';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import useLocales from '~/hooks/use_locales';

type ConfirmDialogProps = {
  title: string;
  content: string | React.Component | React.ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  open: boolean;
  onConfirm: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  confirmButtonType?: 'error' | 'warning' | 'primary';
};

function ConfirmDialog({ title, content, confirmLabel, cancelLabel, onConfirm, onClose, open, confirmButtonType }: ConfirmDialogProps) {
  const { t } = useLocales();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{isString(content) ? <DialogContentText>{content}</DialogContentText> : content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelLabel || t('actions.cancel')}</Button>
        <Button onClick={onConfirm} autoFocus color={confirmButtonType}>
          {confirmLabel || t('actions.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
