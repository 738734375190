/* eslint-disable react/require-default-props */
import { Grid, Box, CardActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { observer } from 'mobx-react-lite';

import useLocales from '~/hooks/use_locales';
import { IReport } from '~/mst/models/report';
// import SubscribersBlock from './subscribers_block';
import DetailsBlock from './details_block';

type FormType = {
  model: IReport;
  invalid: boolean;
  submitting: boolean;
  handleSubmit: VoidFunction;
};

function Form({ model, handleSubmit, invalid, submitting }: FormType) {
  const { t } = useLocales();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box mb={3}>
          <DetailsBlock model={model} />
        </Box>
        <CardActions>
          <LoadingButton disabled={invalid || submitting} type="submit" variant="contained" loading={model?.isSyncing} onClick={handleSubmit}>
            {t('base.buttons.run')}
          </LoadingButton>
        </CardActions>
      </Grid>
    </Grid>
  );
}

export default observer(Form);
