import React, { useCallback, useMemo } from 'react';
import Yup, { searchableSelectSchema } from '~/utils/yup';
import { useParams } from 'react-router-dom';
import { computed } from 'mobx';
import get from 'lodash/get';
import { Form as FinalForm } from 'react-final-form';
import { getIdentifier } from '~/mst/utils';

import I18n from '~/utils/i18n';
import useMst from '~/hooks/use_mst';
import { useValidationSchema } from '~/hooks/use_validate';

import Modal from '~/components/modal';
import HeatmapWidgetStore, { IHeatmapWidget } from '~/mst/models/widget/heatmap_widget';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import { captureException } from '~/services/sentry';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';
import HeatmapWidgetForm from './form';

type HeatmapWidgetConfigurationProps = {
  open: boolean;
  onClose: () => void;
  widget?: IHeatmapWidget;
};

const AddEditHeatmapSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  node_id: Yup.array().required('Device is required').min(1, 'Required at least one device'),
  selected_path: searchableSelectSchema('Parameter is required')
});

function HeatmapWidgetConfiguration({ open, onClose, widget }: HeatmapWidgetConfigurationProps) {
  const { dashboards, nodes } = useMst();
  const { id } = useParams();
  const { t } = useLocales();
  const dashboard = dashboards.getById(id);

  const initialValues = useMemo(
    () =>
      computed(() => {
        if (!widget) {
          return {};
        }
        return {
          name: widget.name,
          node_id: widget.node_id,
          selected_path: widget.selectedPath
        };
      }),
    [widget]
  ).get();

  const validate = useValidationSchema(AddEditHeatmapSchema);

  const handleSave = useCallback(
    (values) => {
      try {
        const selectedPath = values.selected_path?.value;
        const nodeIds = getOptionsForPayload(values.node_id);

        const payload = {
          node_id: nodeIds.filter((nodeId) => Boolean(nodes.getById(nodeId))),
          data_point_id: get(values, 'selected_path.models', []).map((model) => getIdentifier(model)),
          selected_path: selectedPath,
          name: values.name
        };
        if (widget) {
          widget.update(payload);
        } else {
          const newWidget = HeatmapWidgetStore.create(payload);
          const breakpoints = dashboard.widgets.lastPositions(4, 3);
          newWidget.setLayout(breakpoints);
          dashboard.widgets.create(newWidget);
        }
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [widget, dashboard, t, nodes]
  );

  return (
    <Modal open={open} handleClose={onClose} title={!widget ? I18n.t('models.heatmap_widget_create') : I18n.t('models.heatmap_widget_update')}>
      <FinalForm onSubmit={handleSave} initialValues={initialValues} validate={validate}>
        {({ handleSubmit }) => <HeatmapWidgetForm isNew={!widget} onClose={onClose} onSave={handleSubmit} dashboard={dashboard} />}
      </FinalForm>
    </Modal>
  );
}

export default HeatmapWidgetConfiguration;
