import * as nodes from './nodes';
import * as alerts from './alerts';
import * as organizations from './organizations';
import * as partnerships from './partnerships';
import * as users from './users';
import * as invitations from './invitations';
import * as search from './search';
import * as auth from './auth';
import * as webhooks from './webhooks';
import * as tokens from './tokens';
import * as dashboards from './dashboards';
import * as calibrations from './calibrations';
import * as reports from './reports';
import * as snapshots from './snapshots';
import * as thiamis from './thiamis';
import * as firmwares from './firmwares';
import * as analytics from './analytics';

export default {
  alerts,
  nodes,
  organizations,
  partnerships,
  users,
  search,
  auth,
  webhooks,
  tokens,
  invitations,
  dashboards,
  reports,
  thiamis,
  calibrations,
  snapshots,
  firmwares,
  analytics
};
