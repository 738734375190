import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import get from 'lodash/get';

import useMst from '~/hooks/use_mst';
import useFetch from '~/hooks/use_fetch';
import NavigateDropdown from '~/components/navigate_dropdown/organizations_dropdown';
import useSlug from '~/hooks/use_slug';

function OrganizationsDropdown() {
  const store = useMst();
  const { auth } = useMst();
  const [isDisabled, setIsDisabled] = useState(false);
  const { organizations } = store;
  const { pathname } = useLocation();
  const [path, page] = pathname.split('/').slice(2);
  const { slug } = useSlug();

  const { isFetched } = useFetch(organizations, null, { excludeParams: ['organization_id'] });

  const navigate = useNavigate();
  const handleOrganizationChange = useCallback(
    (value) => {
      const pagename = pathname.split('/').slice(2).join('/');
      auth.setOrganizationId(value);
      navigate(`/${value}/${pagename.trim() === '' ? 'home' : pagename}`);
    },
    [pathname, navigate, auth]
  );

  useEffect(() => {
    const isDetailsPage = /^(?=.*[a-z])(?=.*[0-9])|create/.test(page);
    setIsDisabled(isDetailsPage);
  }, [path, page]);

  const hasOrganization = organizations.valueLabelPairsIsMemberWithAll.some(({ value }) => value === slug);

  useEffect(() => {
    if (isFetched) {
      if (slug && auth.organization_id !== slug) {
        handleOrganizationChange(hasOrganization ? slug : get(organizations.valueLabelPairsIsMemberWithAll, '[0].value', 'all'));
      }
    }
  }, [isFetched, hasOrganization, auth.organization_id, slug, handleOrganizationChange, organizations.valueLabelPairsIsMemberWithAll]);

  if (organizations.valueLabelPairsIsMemberWithAll.length === 0) {
    return null;
  }

  return (
    <NavigateDropdown
      blurOnSelect
      loading={organizations.isFetching}
      onChange={handleOrganizationChange}
      options={organizations.valueLabelPairsIsMemberWithAll}
      value={auth.organization_id}
      disabled={isDisabled}
    />
  );
}

export default observer(OrganizationsDropdown);
