/* eslint-disable @typescript-eslint/naming-convention */
import flatten from 'lodash/flatten';
import { getIdentifier } from '~/mst/utils';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import { INode } from '~/mst/models/node';
import { STATUS_WEIGHTS } from '~/utils/constants';
import { getFiltersItemsList } from '~/mst/models/abstract/filterable/actions';
import { getOnlineStatusFromAQ } from '~/mst/models/device/utils';
import type { INodesModel } from './model';

const statusCustomSorting = {
  online: 0,
  poor: 1,
  moderate: 2,
  good: 3,
  offline: 4,
  shared: 5,
  leased: 5,
  deactivated: 6
};

export default (self: INodesModel) => ({
  get actions() {
    return uniq(flatten(self.toArray.map(({ actions }) => actions)));
  },
  get filteredList() {
    return self.filteredModels.map((node: INode) => ({
      _id: getIdentifier(node),
      name: node.name,
      description: node.description,
      serial: node.serial,
      organizationId: node.organization_id,
      organizationName: node.owner?.name,
      organizationExternalId: node.owner?.external_id,
      lastOnline: node.last_online,
      sharedTo: node.sharedTo?.map((org) => ({ name: org?.name, externalId: org?.external_id })),
      leasedTo: node.leasedTo?.map((org) => ({ name: org?.name, externalId: org?.external_id })),
      status: node.statusType(),
      statusText: node.statusText(),
      location: node.thiamis?.locationLatLng,
      isExpandable: node.isMonitorable,
      isCreatable: node.isCreatable,
      isMonitorable: node.isMonitorable,
      isDeletable: node.isDeletable,
      isModifiable: node.isModifiable
    }));
  },
  get filteredListMap() {
    return self.filteredModels.map((node: INode) => {
      const statusType = node.statusType();
      return {
        _id: getIdentifier(node),
        name: node.name,
        thiamis: node.thiamis,
        description: node.description,
        serial: node.serial,
        status: statusType,
        statusType: getOnlineStatusFromAQ(statusType),
        statusText: node.statusText({ showAQStatus: false }),
        location: node.thiamis?.locationLatLng,
        locationDescription: node.thiamis?.locationDescription,
        hasWiFi: node?.thiamis?.hasWiFi,
        hasManualLocation: node?.thiamis?.hasManualLocation,
        interval: node.interval,
        isOnline: node.isOnline
      };
    });
  },
  get getDeviceStatusFiltersItemsList() {
    const filterGroups = getFiltersItemsList(self.filteredModels, 'device-status');
    return sortBy(filterGroups, [({ key }) => statusCustomSorting[key]])
      .map((item) => {
        if (item.key === 'online') {
          // eslint-disable-next-line no-param-reassign
          item.children = filterGroups.filter((filter) => ['good', 'moderate', 'poor'].includes(filter.key));
        }
        return item;
      })
      .filter(({ key }) => ['good', 'moderate', 'poor'].includes(key) === false);
  },
  get monitorableValueLabelPairs() {
    return self.valueLabelPairs.filter(({ model: node }) => node.isMonitorable);
  },
  get monitorableValueLabelPairsSortedByStatus() {
    return sortBy(self.monitorableValueLabelPairs, ({ model }) => -STATUS_WEIGHTS[model?.statusType() || 0]);
  },
  get modifiableValueLabelPairs() {
    return self.valueLabelPairs.filter(({ model: node }) => node.isModifiable);
  },
  get modifiableValueLabelPairsSortedByStatus() {
    return sortBy(self.modifiableValueLabelPairs, ({ model }) => -STATUS_WEIGHTS[model?.statusType() || 0]);
  },
  get airthinxValueLabelPairs() {
    return self.valueLabelPairs.filter(({ model: node }) => node.isAirthinx);
  },
  get monitorableAirthinxValueLabelPairs() {
    return self.airthinxValueLabelPairs.filter(({ model: node }) => node.isMonitorable);
  },
  getNodeByDataPointId(dataPointId) {
    return self.toArray.find((node) => node.data_points?.getById(dataPointId));
  },
  getDataPointByDataPointId(dataPointId) {
    return self.toArray.find((node) => node.data_points?.getById(dataPointId))?.data_points?.getById(dataPointId);
  },
  getDataPointByNodeIdAndPath(nodeId: string, path: string) {
    return self.getById(nodeId)?.data_points?.getByPath(path);
  },
  getDataPointByNodeIdAndPresentName(nodeId: string, presentName: string) {
    return self.getById(nodeId)?.data_points?.getByPresentName(presentName);
  }
});
