import Adam from '~/assets/icons/devices/adam.svg';
import Adr_1500 from '~/assets/icons/devices/adr-1500.svg';
import AethAirCh_2O from '~/assets/icons/devices/aeth-air-ch-2-o.svg';
import AethAirCo_2 from '~/assets/icons/devices/aeth-air-co-2.svg';
import AethAirCo from '~/assets/icons/devices/aeth-air-co.svg';
import AethAirDiff from '~/assets/icons/devices/aeth-air-diff.svg';
import AethAirH_2S from '~/assets/icons/devices/aeth-air-h-2-s.svg';
import AethAirNh_3 from '~/assets/icons/devices/aeth-air-nh-3.svg';
import AethAirNo_2 from '~/assets/icons/devices/aeth-air-no-2.svg';
import AethAirO_3 from '~/assets/icons/devices/aeth-air-o-3.svg';
import AethAirO from '~/assets/icons/devices/aeth-air-o.svg';
import AethAirSo_2 from '~/assets/icons/devices/aeth-air-so-2.svg';
import AethAirVoc from '~/assets/icons/devices/aeth-air-voc.svg';
import AirThinx_2 from '~/assets/icons/devices/air-thinx-2.svg';
import AirThinxPro from '~/assets/icons/devices/air-thinx-pro.svg';
import AirThinx from '~/assets/icons/devices/air-thinx.svg';
import Airmar_150 from '~/assets/icons/devices/airmar-150.svg';
import Airmar from '~/assets/icons/devices/airmar.svg';
import Airthinx from '~/assets/icons/devices/airthinx.svg';
import Airthinx2 from '~/assets/icons/devices/airthinx2.svg';
import AlphaSense from '~/assets/icons/devices/alpha-sense.svg';
import Cirrus from '~/assets/icons/devices/cirrus.svg';
import DataRam from '~/assets/icons/devices/data-ram.svg';
import DavisVintagePro from '~/assets/icons/devices/davis-vintage-pro.svg';
import DavisVue from '~/assets/icons/devices/davis-vue.svg';
import Davis from '~/assets/icons/devices/davis.svg';
import DefaultDevice from '~/assets/icons/devices/default-device.svg';
import DefaultSensor from '~/assets/icons/devices/default-sensor.svg';
import DiverDcx from '~/assets/icons/devices/diver-dcx.svg';
import DustTrak from '~/assets/icons/devices/dust-trak.svg';
import Ebam from '~/assets/icons/devices/ebam.svg';
import Es_642 from '~/assets/icons/devices/es-642.svg';
import Fdpm from '~/assets/icons/devices/FDPM.svg';
import Frog_4000 from '~/assets/icons/devices/frog-4000.svg';
import Gm_460 from '~/assets/icons/devices/gm-460.svg';
import Gx_6000 from '~/assets/icons/devices/gx-6000.svg';
import Healthway from '~/assets/icons/devices/healthway.svg';
import Icountpd from '~/assets/icons/devices/icountpd.svg';
import Jerome from '~/assets/icons/devices/jerome.svg';
import Lufft from '~/assets/icons/devices/lufft.svg';
import Manta_2 from '~/assets/icons/devices/manta-2.svg';
import MaximetGmx100 from '~/assets/icons/devices/maximet-gmx100.svg';
import MaximetGmx200 from '~/assets/icons/devices/maximet-gmx200.svg';
import MaximetGmx300 from '~/assets/icons/devices/maximet-gmx300.svg';
import MaximetGmx301 from '~/assets/icons/devices/maximet-gmx301.svg';
import MaximetGmx400 from '~/assets/icons/devices/maximet-gmx400.svg';
import MaximetGmx500 from '~/assets/icons/devices/maximet-gmx500.svg';
import MaximetGmx531 from '~/assets/icons/devices/maximet-gmx531.svg';
import MaximetGmx600 from '~/assets/icons/devices/maximet-gmx600.svg';
import MiniDiver from '~/assets/icons/devices/mini-diver.svg';
import Minirae from '~/assets/icons/devices/minirae.svg';
import Mts from '~/assets/icons/devices/mts.svg';
import MultiraePlus from '~/assets/icons/devices/multirae-plus.svg';
import ParticlesPlus from '~/assets/icons/devices/particles-plus.svg';
import Partisol from '~/assets/icons/devices/partisol.svg';
import Pdr_1500 from '~/assets/icons/devices/pdr-1500.svg';
import Pom from '~/assets/icons/devices/pom.svg';
import Ppbrae_2000 from '~/assets/icons/devices/ppbrae_2000.svg';
import Ppbrae_3000 from '~/assets/icons/devices/ppbrae_3000.svg';
import QuestTemp from '~/assets/icons/devices/quest-temp.svg';
import Rad7 from '~/assets/icons/devices/rad7.svg';
import Rae from '~/assets/icons/devices/rae.svg';
import ResponseOne from '~/assets/icons/devices/response-one.svg';
import SoundPro from '~/assets/icons/devices/sound-pro.svg';
import Thiamis_820g from '~/assets/icons/devices/thiamis-820g.svg';
import Thiamis_1000Lg from '~/assets/icons/devices/thiamis-1000-lg.svg';
import Thiamis_1000 from '~/assets/icons/devices/thiamis-1000.svg';
import Thiamis from '~/assets/icons/devices/thiamis.svg';
import TsiDusttrak from '~/assets/icons/devices/tsi-dusttrak.svg';
import Ultrarae_3000 from '~/assets/icons/devices/ultrarae_3000.svg';
import Ws200 from '~/assets/icons/devices/ws200.svg';
import Ws300 from '~/assets/icons/devices/ws300.svg';
import Ws303 from '~/assets/icons/devices/ws303.svg';
import Ws400 from '~/assets/icons/devices/ws400.svg';
import Ws401 from '~/assets/icons/devices/ws401.svg';
import Ws500 from '~/assets/icons/devices/ws500.svg';
import Ws503 from '~/assets/icons/devices/ws503.svg';
import Ws510 from '~/assets/icons/devices/ws510.svg';
import Ws600 from '~/assets/icons/devices/ws600.svg';
import Ws800 from '~/assets/icons/devices/ws800.svg';
import YsiExo from '~/assets/icons/devices/ysi-exo.svg';
import Ysi from '~/assets/icons/devices/ysi.svg';
import Fluke_1620A from '~/assets/icons/devices/fluke-1620-a.svg';

export default {
  adam: Adam,
  'adr-1500': Adr_1500,
  'aeth-air-ch-2-o': AethAirCh_2O,
  'aeth-air-co-2': AethAirCo_2,
  'aeth-air-co': AethAirCo,
  'aeth-air-diff': AethAirDiff,
  'aeth-air-h-2-s': AethAirH_2S,
  'aeth-air-nh-3': AethAirNh_3,
  'aeth-air-no-2': AethAirNo_2,
  'aeth-air-o-3': AethAirO_3,
  'aeth-air-o': AethAirO,
  'aeth-air-so-2': AethAirSo_2,
  'aeth-air-voc': AethAirVoc,
  'air-thinx-2': AirThinx_2,
  'air-thinx-pro': AirThinxPro,
  'air-thinx': AirThinx,
  'airmar-150': Airmar_150,
  airmar: Airmar,
  airthinx: Airthinx,
  airthinx2: Airthinx2,
  'alpha-sense': AlphaSense,
  cirrus: Cirrus,
  'data-ram': DataRam,
  'davis-vintage-pro': DavisVintagePro,
  'davis-vue': DavisVue,
  davis: Davis,
  'default-device': DefaultDevice,
  'default-sensor': DefaultSensor,
  'diver-dcx': DiverDcx,
  'dust-trak': DustTrak,
  'dust-trak-8540': DustTrak,
  ebam: Ebam,
  'es-642': Es_642,
  FDPM: Fdpm,
  'frog-4000': Frog_4000,
  'gm-460': Gm_460,
  'gx-6000': Gx_6000,
  healthway: Healthway,
  icountpd: Icountpd,
  jerome: Jerome,
  lufft: Lufft,
  'manta-2': Manta_2,
  'spectra-litmus': Manta_2,
  'maximet-gmx100': MaximetGmx100,
  'maximet-gmx200': MaximetGmx200,
  'maximet-gmx300': MaximetGmx300,
  'maximet-gmx301': MaximetGmx301,
  'maximet-gmx400': MaximetGmx400,
  'maximet-gmx500': MaximetGmx500,
  'maximet-gmx531': MaximetGmx531,
  'maximet-gmx600': MaximetGmx600,
  'mini-diver': MiniDiver,
  minirae: Minirae,
  mts: Mts,
  'multirae-plus': MultiraePlus,
  'particles-plus': ParticlesPlus,
  partisol: Partisol,
  'pdr-1500': Pdr_1500,
  pom: Pom,
  ppbrae_2000: Ppbrae_2000,
  ppbrae_3000: Ppbrae_3000,
  'quest-temp': QuestTemp,
  rad7: Rad7,
  rae: Rae,
  'response-one': ResponseOne,
  'sound-pro': SoundPro,
  'thiamis-820g': Thiamis_820g,
  'thiamis-1000-lg': Thiamis_1000Lg,
  'thiamis-1000': Thiamis_1000,
  thiamis: Thiamis,
  'tsi-dusttrak': TsiDusttrak,
  ultrarae_3000: Ultrarae_3000,
  ws200: Ws200,
  ws300: Ws300,
  ws303: Ws303,
  ws400: Ws400,
  ws401: Ws401,
  ws500: Ws500,
  ws503: Ws503,
  ws510: Ws510,
  ws600: Ws600,
  ws800: Ws800,
  'ysi-exo': YsiExo,
  ysi: Ysi,
  'fluke-1620-a': Fluke_1620A
};
