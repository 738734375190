/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import { getIdentifier } from '~/mst/utils';
import isEmpty from 'lodash/isEmpty';
import api from '~/api';
import type { SearchDataPointsParams } from '~/api/rest/search';
import type { IDataPointsNodeModel } from './model';

export default (self: IDataPointsNodeModel) => ({
  fetch: flow(function* fetch(params: SearchDataPointsParams) {
    try {
      self.startFetching();
      if (!isEmpty(params.data_point_id) || !isEmpty(params.node_id)) {
        const { data } = yield self.sync(api.search.dataPoints, params);
        applySnapshot(self.models, data);
      }
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  createMetric: flow(function* createMetric(payload) {
    try {
      self.startSyncing();
      const nodeId = getIdentifier(self.node);
      const { data } = yield api.nodes.createMetric(nodeId, { node_id: nodeId, _type: 'metric', ...payload });
      self.add(data);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});
