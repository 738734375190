/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ActionsDropdown from '~/components/actions_dropdown';
import { MenuItemStyled } from '~/components/actions_dropdown/styled';
import { PERMISSIONS } from '~/mst/models/permissions/types';
import Modal from '~/components/modal';
import useActions from '~/components/actions_dropdown/context';
import useLocales from '~/hooks/use_locales';
import useModal from '~/hooks/use_modal';
import useMst from '~/hooks/use_mst';
import TransferModal from '~/pages/calibrations/form_top/manage_modal/transfer_modal';
import IdentifyWithLedModal from './manage_modal/identify_with_led_modal';
import ResetModal from './manage_modal/reset_modal';
import RunCalibrationModal from './manage_modal/run_calibration_modal';

function ActionsAcontainer() {
  const { t } = useLocales();
  const { auth } = useMst();
  const { action, setAction, closeMenu } = useActions();
  const { handleClose: handleManageModalClose } = useModal();

  const canCreate = auth.user?.hasPermission(PERMISSIONS.ManageDevices);

  const handleIdentifyDevice = useCallback(() => setAction('identify'), [setAction]);
  const handleTransfer = useCallback(() => setAction('transfer'), [setAction]);
  const handleReset = useCallback(() => setAction('reset'), [setAction]);
  const handleCreateNew = useCallback(() => setAction('run'), [setAction]);

  const deselectAction = useCallback(() => {
    closeMenu();
    setAction(null);
    handleManageModalClose();
  }, [setAction, closeMenu, handleManageModalClose]);

  return (
    <>
      <ActionsDropdown>
        {canCreate && [
          <MenuItemStyled key="create-1" onClick={handleCreateNew}>
            {t('calibrations.manage_actions.run')}
          </MenuItemStyled>,
          <MenuItemStyled key="create-2" onClick={handleIdentifyDevice}>
            {t('calibrations.manage_actions.identify')}
          </MenuItemStyled>,
          <MenuItemStyled key="create-2" onClick={handleReset} divider>
            {t('calibrations.manage_actions.reset')}
          </MenuItemStyled>,
          <MenuItemStyled key="create-3" onClick={handleTransfer}>
            {t('calibrations.manage_actions.transfer')}
          </MenuItemStyled>
        ]}
      </ActionsDropdown>
      <Modal open={!!action} handleClose={deselectAction} title={t(`calibrations.manage_actions.${action}`)}>
        {(() => {
          // eslint-disable-next-line default-case
          switch (action) {
            case 'transfer':
              return <TransferModal toggleModal={deselectAction} />;
            case 'identify':
              return <IdentifyWithLedModal toggleModal={deselectAction} />;
            case 'reset':
              return <ResetModal toggleModal={deselectAction} />;
            case 'run':
              return <RunCalibrationModal toggleModal={deselectAction} />;
          }
        })()}
      </Modal>
    </>
  );
}

export default observer(ActionsAcontainer);
