/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import pick from 'lodash/pick';
import api from '~/api';
import { IUserModel } from './model';

export default (self: IUserModel) => ({
  fetch: flow(function* fetch() {
    try {
      self.startFetching();
      const { data } = yield api.users.read(self.id);
      applySnapshot(self, data);
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  update: flow(function* update(form) {
    self.startSyncing();
    const fields = ['first_name', 'last_name', 'phone_number', 'timezone', 'picture', 'locale', 'temp_units'];
    try {
      const payload = pick(form, fields);
      const { data } = yield api.users.update(self.id, payload);
      applySnapshot(self, data);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  deletePermission: flow(function* deletePermission(externalId) {
    self.startSyncing();
    try {
      yield api.users.deletePermission(self.id, externalId);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});
