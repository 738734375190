/* eslint-disable @typescript-eslint/naming-convention */
import { getParent } from 'mobx-state-tree';
import { getIdentifier } from '~/mst/utils';
import { DateTime, Interval } from 'luxon';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import chunk from 'lodash/chunk';
import type { IAlertMonitorModel } from '../model';
import type { IChartTableWidgetModel } from './model';
import DurationsTableRowModel from './durations_table_row';

type ChartDataItem = {
  x: number;
  y: number;
  x2: number;
  dataPointId: string;
};
export default (self: IChartTableWidgetModel) => {
  function getChartData(): ChartDataItem[] {
    return self.data_points.toArray?.map((dp, index) => {
      const isFirstInactive = get(dp.measurements, '[0][1]') === false;
      return chunk(isFirstInactive ? dp.measurements.slice(1) : dp.measurements, 2).map(([from, to]) => ({
        x: from[0],
        dataPointId: getIdentifier(dp),
        name: dp.description,
        x2: to ? to[0] : DateTime.now().toMillis(),
        y: index
      }));
    });
  }
  return {
    get chartData() {
      return flatten(getChartData());
    },
    get tableData() {
      const monitor: IAlertMonitorModel = getParent(self);
      const data = flatten(getChartData()).map(({ x, x2, dataPointId, name }) =>
        DurationsTableRowModel.create({
          from: x,
          to: x2,
          name,
          duration: Interval.fromDateTimes(DateTime.fromMillis(x), DateTime.fromMillis(x2)).toDuration().valueOf(),
          data_point_id: dataPointId,
          alert_id: monitor.alert_id!
        })
      );
      if (self.zoomLevel != null) {
        const { min, max } = self.zoomLevel;
        return data.filter(({ from, to }) => from <= max && to >= min);
      }
      return data;
    },
    get chartCategories() {
      return self.data_points.toArray?.map((dp) => getIdentifier(dp));
    }
  };
};
