/* eslint-disable @typescript-eslint/naming-convention */
import { getIdentifier } from '~/mst/utils';
import { IReferenceDeviceModel } from './model';

export default (self: IReferenceDeviceModel) => ({
  get presentName() {
    const { name, serial } = self;
    return `[${serial}] ${name}`;
  },
  get valueLabelPair() {
    return { label: self.presentName, value: getIdentifier(self), model: self };
  }
});
