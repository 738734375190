import { observer } from 'mobx-react-lite';
import { Portal, IconButton, Tooltip } from '@mui/material';
import { getIdentifier } from '~/mst/utils';
import PlusCircleOutline from '@iconify/icons-eva/plus-circle-fill';
import { Icon } from '@iconify/react';
import useMediaQuery from '@mui/material/useMediaQuery';

import Scrollbar from '~/components/sidebar/scrollbar';
import LoadingBox from '~/components/loading_box';
import useResponsive from '~/hooks/use_responsive';
import type { IDeviceMonitor } from '~/pages/device_monitor/store';
import AddStatisticalParamModal from '~/components/add_statistical_param_modal';
import useModal from '~/hooks/use_modal';
import useLocales from '~/hooks/use_locales';

import StatusBar from './status_bar';
import ParamItem from './param_item';
import { CatcherStyled, SidebarOuterStyled, SidebarInnerStyled, SidebarContainerStyled } from './styled';

type SidebarType = {
  isExpanded?: boolean;
  onToggleCollapse?: VoidFunction;
  report: IDeviceMonitor;
};

function Sidebar({ isExpanded, onToggleCollapse, report }: SidebarType) {
  const isMobile = useResponsive('down', 'md');
  const { open, handleClose: handleModalClose, handleOpen: handleModalOpen } = useModal();
  const { t } = useLocales();
  const isPrint = useMediaQuery('print');

  return (
    <Portal disablePortal={isMobile === false || isPrint} sx={{ displayPrint: 'none' }}>
      <SidebarContainerStyled $isExpanded={isExpanded}>
        {isMobile && !isPrint && (
          <Portal>
            <CatcherStyled $isExpanded={isExpanded} onClick={onToggleCollapse} />
          </Portal>
        )}

        <SidebarOuterStyled $isExpanded={isExpanded}>
          <SidebarInnerStyled $isExpanded={isExpanded}>
            <Scrollbar sx={{ height: '100%', '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' } }}>
              {report?.nodeWidget?.isFetching ? (
                <LoadingBox />
              ) : (
                report?.sidebarParams?.map((dp) => <ParamItem key={getIdentifier(dp)} isExpanded={isExpanded} report={report} dataPoint={dp} />)
              )}
            </Scrollbar>
            <StatusBar node={report?.nodeWidget?.node} />
            <Tooltip title={t('sensor.data_point.add_title')} placement="top">
              <IconButton
                variant="contained"
                size="large"
                onClick={handleModalOpen}
                sx={{ position: 'absolute', bottom: '45px', right: '5px', zIndex: 9999, color: 'primary.main' }}
              >
                <Icon icon={PlusCircleOutline} />
              </IconButton>
            </Tooltip>
          </SidebarInnerStyled>
        </SidebarOuterStyled>
        <AddStatisticalParamModal open={open} onClose={handleModalClose} node={report.nodeWidget?.node} />
      </SidebarContainerStyled>
    </Portal>
  );
}

export default observer(Sidebar);
