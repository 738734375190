import { types as t, SnapshotOut } from 'mobx-state-tree';
import { persist } from 'mobx-state-tree-persist';

import NodesStore, { INodesStore } from '~/mst/models/nodes';
import AlertsStore, { IAlertsStore } from '~/mst/models/alerts';
import OrganizationsStore, { IOrganizationsStore } from '~/mst/models/organizations';
import PartnershipsStore, { IPartnershipsStore } from '~/mst/models/partnerships';
import CertificatesStore, { ICertificatesStore } from '~/mst/models/certificates';
import TemplatesStore, { ITemplatesStore } from '~/mst/models/templates';
import UsersStore, { IUsersStore } from '~/mst/models/users';
import WebhooksStore, { IWebhooksStore } from '~/mst/models/webhooks';
import TokensStore, { ITokensStore } from '~/mst/models/tokens';
import InvitationsStore, { IInvitationsStore } from '~/mst/models/invitations';
import VersionStore, { IVersionStore } from '~/mst/models/version';
import DashboardsStore, { IDashboardsStore } from '~/mst/models/dashboards';
import ReportsStore, { IReportsStore } from '~/mst/models/reports';
import CalibrationsStore, { ICalibrationsStore } from '~/mst/models/calibrations';
import ArchivesStore, { IArchivesStore } from '~/mst/models/archives';
import AuthStore, { IAuthStore } from '~/mst/models/auth';
import StatsStore, { IStatsStore } from '~/mst/models/stats';
import GptStore, { IGptStore } from '~/mst/models/gpt';
import ChatsStore, { IChatsStore } from '~/mst/models/chats';
import ReferenceDevicesStore, { IReferenceDevicesStore } from '~/mst/models/reference_devices';
import HomeStore, { IHomeStore } from '~/pages/home/store';
import DeviceMonitorStore, { IDeviceMonitor } from '~/pages/device_monitor/store';

export const RootStore = t.model({
  nodes: t.optional(NodesStore, []),
  alerts: t.optional(AlertsStore, []),
  certificates: t.optional(CertificatesStore, []),
  templates: t.optional(TemplatesStore, []),
  organizations: t.optional(OrganizationsStore, []),
  partnerships: t.optional(PartnershipsStore, []),
  users: t.optional(UsersStore, []),
  webhooks: t.optional(WebhooksStore, []),
  tokens: t.optional(TokensStore, []),
  dashboards: t.optional(DashboardsStore, []),
  reports: t.optional(ReportsStore, []),
  invitations: t.optional(InvitationsStore, []),
  calibrations: t.optional(CalibrationsStore, []),
  archives: t.optional(ArchivesStore, []),
  referenceDevices: t.optional(ReferenceDevicesStore, []),
  version: t.optional(VersionStore, {}),
  stats: t.optional(StatsStore, {}),
  gpt: t.optional(GptStore, {}),
  chats: t.optional(ChatsStore, {
    models: []
  }),
  auth: t.optional(AuthStore, {}),
  home: t.optional(HomeStore, {
    widgets: [
      {
        id: 'devicesOnlineChartWidgetId',
        type: 'DevicesOnlineChartWidget'
      },
      {
        id: 'totalDevicesOnlinePieChartWidgetId',
        type: 'TotalDevicesOnlinePieChartWidget',
        chart_type: 'pie_chart'
      },
      {
        id: 'totalDevicesOnlineColumnChartWidgetId',
        type: 'TotalDevicesOnlineColumnChartWidget',
        chart_type: 'column_chart'
      },
      {
        id: 'recentAlertsWidgetId',
        type: 'RecentAlertsWidget'
      }
    ]
  }),
  deviceMonitor: t.optional(DeviceMonitorStore, {
    range: {
      from: { hours: 3 }
    }
  })
});

export interface IRootStore {
  nodes: INodesStore;
  alerts: IAlertsStore;
  certificates: ICertificatesStore;
  templates: ITemplatesStore;
  organizations: IOrganizationsStore;
  partnerships: IPartnershipsStore;
  users: IUsersStore;
  webhooks: IWebhooksStore;
  tokens: ITokensStore;
  dashboards: IDashboardsStore;
  reports: IReportsStore;
  invitations: IInvitationsStore;
  calibrations: ICalibrationsStore;
  archives: IArchivesStore;
  referenceDevices: IReferenceDevicesStore;
  version: IVersionStore;
  home: IHomeStore;
  auth: IAuthStore;
  stats: IStatsStore;
  chats: IChatsStore;
  gpt: IGptStore;
  deviceMonitor: IDeviceMonitor;
}
export interface IRootStoreSnapshotOut extends SnapshotOut<typeof RootStore> {}

const rootStore = RootStore.create();
export const persistedStore = persist([
  [rootStore.auth, { key: 'auth', storage: localStorage, whitelist: ['theme', 'organization_id', 'locale'] }],
  [rootStore.chats, { key: 'chats', storage: localStorage }]
]);

export default rootStore;
