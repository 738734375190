import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { ModelV2 } from '~/mst/models/abstract/model';
import DateType from '~/mst/models/abstract/date_time';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import MaybeString from '~/mst/models/abstract/maybe_string';
import UserPermissionsModel from '~/mst/models/permissions/user';
import CreatedAtModel from '~/mst/models/abstract/created_at';
import views from './views';

export enum TempUnitTypes {
  Celsius = '°C',
  Fahrenheit = '°F'
}
export const TempUnits = Object.values(TempUnitTypes);

const UserModel = t
  .compose(
    t.model({
      email: t.string,
      external_id: t.string,
      first_name: MaybeString,
      last_name: MaybeString,
      locale: t.maybeNull(MaybeString),
      intercom_identity: t.maybeNull(MaybeString),
      last_login_at: t.maybeNull(DateType),
      phone_number: t.maybeNull(MaybeString),
      timezone: t.maybeNull(MaybeString),
      picture: t.maybeNull(MaybeString),
      temp_units: t.maybeNull(t.enumeration('TempUnit', TempUnits)),
      permissions: UserPermissionsModel
    }),
    ModelV2,
    Syncable,
    CreatedAtModel,
    Fetchable()
  )
  .views(views);

export interface IUserModel extends Instance<typeof UserModel> {}
export interface IUserModelSnapshotOut extends SnapshotOut<typeof UserModel> {}

export default UserModel;
