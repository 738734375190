import { Box, styled, BoxProps, Stack, Badge } from '@mui/material';
import { transientOptions } from '~/utils/styled';
import CloseIcon from '@mui/icons-material/Close';

interface IPanelType extends BoxProps {
  $isVisible: boolean;
}

const PanelStyled = styled(
  Box,
  transientOptions
)<IPanelType>(({ $isVisible, theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: '-50%',
  width: '50%',
  background: theme.palette.background.paper,
  zIndex: 9999,
  cursor: 'default',
  transition: 'right .2s',
  overflowY: 'auto',
  backgroundColor: '#f4f4f4',
  boxShadow: theme.palette.themeShadows.filterPanel,
  ...($isVisible && {
    right: 0
  }),
  ...(!$isVisible && {
    boxShadow: 'none'
  })
}));

export const PanelInnerStyled = styled(Stack)(() => ({
  position: 'relative'
}));

export const BadgeStyled = styled(Badge)(() => ({
  padding: 0
}));

export const CloseIconStyled = styled(CloseIcon)(() => ({
  fontSize: 16
}));

export default PanelStyled;
