/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import { DateTime } from 'luxon';
import api from '~/api';
import type { IAnalyticsStore } from '.';

export default (self: IAnalyticsStore) => ({
  reportsSummary: flow(function* reportsSummary(values: any) {
    try {
      self.startSyncing();
      const { from, to, node_ids: nodeIds, prompt, parameters } = values;
      yield self.nodes.fetch({
        node_id: nodeIds,
        includes: ['profiles', 'data_points'],
        type: 'thiamis'
      });
      self.selected_parameters = parameters;
      if (self.selectedDataPointIds.length) {
        yield self.data_points.fetch({
          data_point_id: self.selectedDataPointIds,
          aggregate: '60',
          to: DateTime.fromISO(to).toMillis(),
          from: DateTime.fromISO(from).toMillis()
        });
      }
      const payload = {
        prompt: `${prompt}\n\nParameters: ${parameters.join('\n')}\n\nData:\n${JSON.stringify(self.data, null, 2)}`
      };
      const { data } = yield api.analytics.reportsTest(payload);
      self.response = data.response;
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  fetch: flow(function* fetch(values: any) {
    try {
      self.startSyncing();
      const { from, to, node_ids: nodeIds, parameters } = values;
      yield self.nodes.fetch({
        node_id: nodeIds,
        includes: ['profiles', 'data_points'],
        type: 'thiamis'
      });
      self.selected_parameters = parameters;
      if (self.selectedDataPointIds.length) {
        yield self.data_points.fetch({
          data_point_id: self.selectedDataPointIds,
          aggregate: '60',
          to: DateTime.fromISO(to).toMillis(),
          from: DateTime.fromISO(from).toMillis()
        });
      }
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  })
});
