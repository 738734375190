/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import openai from '~/services/openai';
import Message from '~/mst/models/chat/message';
import { IMessagesModel } from './model';

export default (self: IMessagesModel) => ({
  fetch: flow(function* create(threadId) {
    try {
      self.startFetching();
      const { data } = yield openai.beta.threads.messages.list(threadId, { limit: 100, order: 'asc' });
      applySnapshot(self.models, data);
      self.finishFetching();
    } catch (e) {
      self.failFetching(e);
    }
  }),
  create: flow(function* create(threadId, content) {
    const message = yield openai.beta.threads.messages.create(threadId, { role: 'user', content });
    self.add(Message.create(message));
  })
});
