/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { getRoot, getIdentifier as getIdentifierMst, isStateTreeNode } from 'mobx-state-tree';
import isEmpty from 'lodash/isEmpty';
import type { ValueLabelPair } from '~/mst/models/abstract/collection_types';
import type { IRootStore } from './store';

export function toMapById(data: Record<string, any>, idAttribute = '_id') {
  if (isEmpty(data)) {
    return {};
  }
  return data.reduce((acc: any, item: any) => {
    acc[item[idAttribute] || item.id] = item;
    return acc;
  }, {});
}

export function fromMap(map: any) {
  return Array.from(map.values());
}

export function getStore(node) {
  return getRoot<IRootStore>(node);
}

export function getValueLabelPairs(values: string[]): ValueLabelPair[] {
  return values?.map((value) => ({ label: value, value }));
}

export function isMstError(thrown) {
  return thrown.message.includes('[mobx-state-tree]');
}

export function getIdentifier(node) {
  if (!isStateTreeNode(node)) {
    return node;
  }
  return getIdentifierMst(node);
}
