import { getParent, hasParent } from 'mobx-state-tree';
import { getIdentifier } from '~/mst/utils';
import type { IUserCalibrationModel } from './model';

export default (self: IUserCalibrationModel) => ({
  get presentName() {
    if (hasParent(self, 5)) {
      const node = getParent(self, 5);
      return `${node.getDataPointByPath(getIdentifier(self))?.presentName} (${self.params})`;
    }
    return null;
  }
});
