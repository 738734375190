import { Box } from '@mui/material';
import { MuiMarkdown } from 'mui-markdown';
import { observer } from 'mobx-react-lite';
import type { IAnalyticsStore } from '~/mst/models/analytics';

type ResultsProps = {
  model: IAnalyticsStore;
};

function Results({ model }: ResultsProps) {
  return (
    <Box
      sx={{
        p: 3,
        minHeight: 300,
        alignItems: 'flex-start',
        h1: {
          fontSize: 32,
          marginBottom: 2
        },
        h2: {
          fontSize: 24,
          marginTop: 2,
          marginBottom: 2
        },
        h3: {
          fontSize: 18,
          marginBottom: 2,
          marginTop: 2
        }
      }}
    >
      <MuiMarkdown>{model.response?.replace('```markdown', '').replace('```', '')}</MuiMarkdown>
    </Box>
  );
}

export default observer(Results);
