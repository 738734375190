import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { ModelV2 as Model } from '~/mst/models/abstract/model';
import CreatedAtModel from '~/mst/models/abstract/created_at';
import Syncable from '~/mst/models/abstract/syncable';

const MessageModel = t
  .compose(
    t.model({
      content: t.frozen(),
      role: t.maybeNull(t.string),
      assistant_id: t.maybeNull(t.string),
      run_id: t.maybeNull(t.string),
      thread_id: t.maybeNull(t.string)
    }),
    Syncable,
    Model,
    CreatedAtModel
  )
  .named('Message');

export interface IMessageModel extends Instance<typeof MessageModel> {}
export interface IMessageModelSnapshotOut extends SnapshotOut<typeof MessageModel> {}

export default MessageModel;
