import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';
import actions from './actions';

const Chat = model.views(views).actions(actions);

export interface IChat extends Instance<typeof Chat> {}

export default Chat;
