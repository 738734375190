import React, { useEffect, useMemo } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Stack, IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useFormState } from 'react-final-form';
import { observable, action, computed } from 'mobx';
import groupBy from 'lodash/fp/groupBy';
import sortBy from 'lodash/fp/sortBy';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';

import Select from '~/components/final_form/select';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import useFetch from '~/hooks/use_fetch';
import DataPoints from '~/mst/models/data_points/node';
import compact from 'lodash/compact';
import { ReportTypes } from '~/mst/models/report/types';
import { CirclePlusStyled, ButtonTextStyled, ButtonContainerStyled } from '~/pages/alert_edit/conditions_block/styled';
import { filterByUserSettings } from '~/mst/models/data_points/node/views';
import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import Parameter from './parameter';

const paramsNodeIds = observable(
  {
    node_id: null,
    setNodeId(value) {
      this.node_id = value;
    }
  },
  {
    setNodeId: action
  }
);
function DataPointsField() {
  const { auth } = useMst();
  const { t } = useLocales();
  const {
    values: {
      configuration: { node_ids: nodeIds, type }
    }
  } = useFormState({ subscription: { values: true } });
  const dataPoints = useMemo(() => DataPoints.create({}), []);
  useEffect(() => paramsNodeIds.setNodeId(getOptionsForPayload(nodeIds)), [nodeIds]);

  useFetch(dataPoints, paramsNodeIds);

  const tempUnits = auth?.user?.temp_units;
  const dataPointsValueLabelPairs = useMemo(
    () => computed(() => filterByUserSettings(dataPoints.defaultValueLabelPairs, tempUnits)),
    [dataPoints.defaultValueLabelPairs, tempUnits]
  ).get();

  const parameterOptions = useMemo(
    () =>
      computed(() => {
        return flow(
          compact,
          groupBy(({ label }) => label),
          map.convert({ cap: false })((values, name) => ({ models: values.map((value) => value.model), label: name, value: name })),
          sortBy('label')
        )(dataPointsValueLabelPairs);
      }),
    [dataPointsValueLabelPairs]
  ).get();

  if ([ReportTypes.parameters_monitor, ReportTypes.perimeter_monitor].includes(type)) {
    return (
      <FieldArray name="configuration.parameters">
        {({ fields }) => {
          return (
            <>
              {fields.map((name, index) => (
                <Parameter fields={fields} name={name} key={name} index={index} options={parameterOptions} isLoading={dataPoints.isFetching} />
              ))}
              <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
                <ButtonContainerStyled
                  data-testid="addParameterBtn"
                  onClick={() => {
                    fields.push({ name: '', alert_level: '' });
                  }}
                >
                  <IconButton>
                    <CirclePlusStyled />
                  </IconButton>
                  <ButtonTextStyled>Add Parameter</ButtonTextStyled>
                </ButtonContainerStyled>
              </Stack>
            </>
          );
        }}
      </FieldArray>
    );
  }

  return (
    <Select
      searchable
      multiple
      name="configuration.parameters"
      label={t('thiamis.download_csv.parameters')}
      options={parameterOptions}
      loading={dataPoints.isFetching}
    />
  );
}

export default observer(DataPointsField);
