/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import openai from '~/services/openai';
import ThreadModel from '../thread';
import { IThreadsModel } from './model';

export default (self: IThreadsModel) => ({
  create: flow(function* create(params = {}) {
    const response = yield openai.beta.threads.create(params);
    self.add(ThreadModel.create(response));
    return response;
  })
});
