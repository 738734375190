import { types as t, Instance } from 'mobx-state-tree';
import { getIdentifier } from '~/mst/utils';
import uniqBy from 'lodash/uniqBy';

const DeviceModel = t.model({
  com_port: t.string,
  com_type: t.string,
  address: t.maybeNull(t.string)
});

const AvailableDeviceModel = t
  .model({
    name: t.identifier,
    connections: t.array(DeviceModel)
  })
  .views((self) => ({
    get valueLabelPair() {
      // @ts-ignore
      return { label: self.name, value: getIdentifier(self), model: self };
    },
    get comPortValueLabelPairs() {
      return self.connections.map(({ com_port }) => ({ value: com_port, label: com_port }));
    },
    get comTypeValueLabelPairs() {
      return uniqBy(
        self.connections.map(({ com_type }) => ({ value: com_type, label: com_type })),
        ({ value }) => value
      );
    }
  }));

export interface IAvailableDeviceModel extends Instance<typeof AvailableDeviceModel> {}

export default AvailableDeviceModel;
