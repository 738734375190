import React, { useEffect, useMemo } from 'react';
import { CardContent } from '@mui/material';
import { OnChange } from 'react-final-form-listeners';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/fp/groupBy';
import compact from 'lodash/fp/compact';
import sortBy from 'lodash/fp/sortBy';
import { action, computed, observable } from 'mobx';

import { useForm, useFormState } from 'react-final-form';
import { observer } from 'mobx-react-lite';

import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import DataPoints from '~/mst/models/data_points/node';
import DevicesSelect from '~/components/devices_select';
import Autocomplete from '~/components/final_form/select';
import DateTimePicker from '~/components/final_form/date_time_picker';
import { ModalActions } from '~/components/modal';
import FormGrid from '~/components/@material-extend/form_grid';
import useFetch from '~/hooks/use_fetch';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import { PATHS } from '~/utils/constants';
import { filterByUserSettings } from '~/mst/models/data_points/node/views';

type ModalFormProps = {
  handleCancel: VoidFunction;
  handleSubmit: VoidFunction;
};

const params = observable(
  {
    node_id: null,
    last: 1,
    setNodeId(value) {
      this.node_id = value;
    }
  },
  {
    setNodeId: action
  }
);

function ModalForm({ handleCancel, handleSubmit }: ModalFormProps) {
  const { t } = useLocales();
  const { nodes, auth } = useMst();
  const { change } = useForm();
  const {
    values: { devices, from, to, timezone },
    hasValidationErrors
  } = useFormState({ subscription: { values: true, hasValidationErrors: true } });

  useEffect(() => params.setNodeId(getOptionsForPayload(devices)), [devices]);

  const dataPoints = useMemo(() => DataPoints.create({}), []);
  useFetch(dataPoints, params);

  const tempUnits = auth?.user?.temp_units;
  const dataPointsValueLabelPairs = useMemo(
    () => computed(() => filterByUserSettings(dataPoints.defaultValueLabelPairs, tempUnits)),
    [dataPoints.defaultValueLabelPairs, tempUnits]
  ).get();

  const parameterOptions = useMemo(
    () =>
      computed(() => {
        return flow(
          compact,
          groupBy(({ label }) => label),
          map.convert({ cap: false })((values, name) => ({ models: values.map(({ model }) => model), label: name, value: name })),
          sortBy('label')
        )(dataPointsValueLabelPairs);
      }),
    [dataPointsValueLabelPairs]
  ).get();

  const now = Date.now();
  return (
    <>
      <CardContent>
        <FormGrid>
          <DevicesSelect
            multiple
            allowSelectAll
            name="devices"
            label={t('thiamis.download_csv.devices')}
            options={nodes.monitorableValueLabelPairs}
          />
          <Autocomplete
            searchable
            multiple
            allowSelectAll
            name="parameters"
            label={t('thiamis.download_csv.parameters')}
            options={parameterOptions}
            loading={dataPoints.isFetching}
          />
          <FormGrid columns="2">
            <DateTimePicker name="from" label={t('thiamis.download_csv.from')} maxDateTime={to || now} timezone={timezone} />
            <DateTimePicker name="to" label={t('thiamis.download_csv.to')} maxDateTime={now} minDate={from} timezone={timezone} />
          </FormGrid>
        </FormGrid>
      </CardContent>
      <OnChange name="devices">
        {(value) => {
          if (value) {
            const deviceTimezone = dataPoints.getByPath(PATHS.TIMEZONE)?.lastValue;
            if (!isEmpty(deviceTimezone)) {
              change('timezone', deviceTimezone);
            }
          }
          dataPoints.reset();
          change('parameters', []);
        }}
      </OnChange>
      <ModalActions onSave={handleSubmit} onClose={handleCancel} saveLabel={t('base.buttons.download')} disabled={hasValidationErrors} />
    </>
  );
}

export default observer(ModalForm);
