/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Chat from '~/mst/models/chat';
import Fetchable from '~/mst/models/abstract/fetchable';
import Syncable from '~/mst/models/abstract/syncable';
import CollectionArray from '~/mst/models/abstract/collection_array';

const ChatsModel = t.compose(CollectionArray(Chat), Syncable, Fetchable({ cache: true }));

export interface IChatsModel extends Instance<typeof ChatsModel> {}

export default ChatsModel;
