import { Box, Stack } from '@mui/material';
import { MuiMarkdown } from 'mui-markdown';
import { observer } from 'mobx-react-lite';
import type { IChat } from '~/mst/models/chat';

type TextDeltaType = {
  model: IChat;
};

function TextDelta({ model }: TextDeltaType) {
  if (model.text_delta == null) {
    return null;
  }
  return (
    <Stack direction="row" justifyContent="flex-start">
      <Box
        sx={{
          margin: '8px 0',
          padding: '16px',
          borderRadius: '8px',
          backgroundColor: '#fff',
          display: 'inline-block'
        }}
      >
        <MuiMarkdown>{model.text_delta}</MuiMarkdown>
      </Box>
    </Stack>
  );
}

export default observer(TextDelta);
