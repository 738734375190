import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Box, Chip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import isArray from 'lodash/isArray';

import { ListItemStyled, ExternalIdStyled } from '~/components/devices_select/styled';
import DataPoints from '~/mst/models/data_points/node';
import I18n from '~/utils/i18n';
import { action, observable, computed } from 'mobx';

import TextInput from '~/components/final_form/text_input';
import useFetch from '~/hooks/use_fetch';
import useMst from '~/hooks/use_mst';
import Select from '~/components/final_form/select';
import DevicesSelect from '~/components/devices_select';
import FormGrid from '~/components/@material-extend/form_grid';
import WidgetConfigurationForm from '~/components/widgets/common/configuration_form';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import { filterByUserSettings } from '~/mst/models/data_points/node/views';
import { GroupHeader } from '../../chart_widget/configuration/styled';

type FormProps = {
  onClose: () => void;
  onSave: () => void;
  isNew?: boolean;
};

const params = observable(
  {
    node_id: null,
    setNodeId(value) {
      this.node_id = value;
    }
  },
  {
    setNodeId: action
  }
);

export function NodeWidgetConfigurationForm({ onClose, onSave, isNew }: FormProps) {
  const { change } = useForm();
  const { nodes, auth } = useMst();

  const {
    values: { node_id: nodeIds },
    invalid
  } = useFormState({ subscription: { values: true, invalid: true } });

  useEffect(() => {
    if (nodeIds) {
      params.setNodeId(getOptionsForPayload(isArray(nodeIds) ? nodeIds : [nodeIds]).filter((id) => Boolean(nodes.getById(id))));
    }
  }, [nodes, nodeIds]);

  const dataPoints = useMemo(() => DataPoints.create({}), []);
  useFetch(dataPoints, params);

  const tempUnits = auth?.user?.temp_units;
  const dataPointsOptions = useMemo(
    () =>
      computed(() =>
        filterByUserSettings(
          dataPoints.defaultValueLabelPairs.sort(
            // eslint-disable-next-line no-unsafe-optional-chaining
            (a, b) => -nodes.getById(b.model.node_id)?.presentName.localeCompare(nodes.getById(a.model.node_id)?.presentName)
          ),
          tempUnits
        )
      ),
    [dataPoints, tempUnits, nodes]
  ).get();

  const handleSave = useCallback(() => {
    onSave();
    onClose();
  }, [onSave, onClose]);

  return (
    <WidgetConfigurationForm isNew={isNew} onClose={onClose} onSave={handleSave} disabled={invalid}>
      <FormGrid>
        <TextInput name="name" label="Name" />
        <DevicesSelect
          multiple
          allowSelectAll
          name="node_id"
          label={I18n.t('models.node')}
          options={nodes.monitorableValueLabelPairs}
          loading={nodes.isFetching}
        />
        <Select
          searchable
          name="selected_data_point_ids"
          label={I18n.t('models.parameter')}
          loading={dataPoints.isFetching}
          multiple
          allowSelectAll
          options={dataPointsOptions}
          groupBy={({ model }) => model?.node_id}
          disableCloseOnSelect
          handleHomeEndKeys={false}
          renderGroup={(groups) => {
            const node = nodes.getById(groups.group);
            return (
              <li key={groups.key}>
                <GroupHeader>
                  <ListItemStyled>
                    {node?.presentName}
                    <ExternalIdStyled className="ExternalId">{node?.serial}</ExternalIdStyled>
                  </ListItemStyled>
                </GroupHeader>
                <ul>{groups.children}</ul>
              </li>
            );
          }}
          renderTags={(tagValue, getTagProps) => {
            return (
              <Box sx={{ maxHeight: (theme) => theme.spacing(16), overflowY: 'auto' }}>
                {tagValue
                  .filter((option) => Boolean(option))
                  .map((option, index) => (
                    <Chip
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getTagProps({ index })}
                      label={nodeIds?.length === 1 ? option?.label : `${option?.label} (${nodes.getById(option?.model?.node_id)?.presentName})`}
                    />
                  ))}
              </Box>
            );
          }}
        />
      </FormGrid>
      <OnChange name="node_id">{() => change('selected_data_point_ids', [])}</OnChange>
    </WidgetConfigurationForm>
  );
}

export default observer(NodeWidgetConfigurationForm);
