/* eslint-disable no-param-reassign */
import { flow, getParent } from 'mobx-state-tree';
import openai from '~/services/openai';
import type { IChatsStore } from '~/mst/models/chats';
import Message from './message';
import type { IChat } from '.';

export default (self: IChat) => ({
  destroy: flow(function* destroy() {
    self.startSyncing();
    try {
      yield openai.beta.assistants.del(self.assistant_id);
      const models = getParent<IChatsStore>(self, 2);
      models.remove(self);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  start() {
    return new Promise((resolve, reject) => {
      try {
        self.startSyncing();
        let newMessage;
        const stream = openai.beta.threads.runs.stream(self.thread_id, { assistant_id: self.assistant_id });
        stream
          .on('messageCreated', (message) => {
            console.log('messageCreated', message);
            newMessage = message;
          })
          .on('runStepCreated', (runStep) => {
            console.log('runStepCreated', runStep);
          })
          .on('textCreated', (text) => {
            console.log('textCreated', text);
          })
          .on('textDelta', (textDelta, snapshot) => {
            console.log('textDelta', snapshot);
            self.setThinking(false);
            self.setTextDelta(textDelta.value);
          })
          .on('end', () => {
            resolve({ status: 'completed' });
            if (newMessage) {
              const message = Message.create(newMessage);
              message?.setContent(self.text_delta);
              self.messages.add(message);
              self.emptyTextDelta();
            }
          });

        self.finishSyncing();
      } catch (e) {
        console.error(e);
        self.failSyncing(e);
        reject(e);
      }
    });
  },
  emptyTextDelta() {
    self.text_delta = '';
  },
  setTextDelta(value) {
    self.text_delta += value;
  },
  setThinking(value) {
    self.thinking = value;
  },
  setProgress(value) {
    self.progress = value;
  },
  createMessage: flow(function* createMessage(message) {
    try {
      self.thinking = true;
      yield self.messages.create(self.thread_id, message);
      yield self.start();
    } catch (e) {}
  })
});
