/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';
import Syncable from '~/mst/models/abstract/syncable';
import CollectionMap from '~/mst/models/abstract/collection_map';
import Assistant from '../assistant';

const AssistantsModel = t.compose(CollectionMap(Assistant), Syncable, Fetchable({ cache: true }));

export interface IAssistantsModel extends Instance<typeof AssistantsModel> {}

export default AssistantsModel;
